import React from 'react';
import PropTypes from 'prop-types';
import IO from 'components/io';
import Img from 'gatsby-image';
import { Container, FirstImg, SecondImg } from './residencesIntro.scss.js';

const ResidencesIntro = ({ title, image1, image2 }) => {
  return (
    <IO rootMargin="150px">
      {({ isVisible }) => (
        <Container isVisible={isVisible}>
          <FirstImg isVisible={isVisible}>
            <Img
              fluid={image1 ? image1.childImageSharp.fluid : {}}
              alt={title}
            />
            <h1>{title}</h1>
          </FirstImg>
          <SecondImg isVisible={isVisible}>
            <Img
              fluid={image2 ? image2.childImageSharp.fluid : {}}
              alt={title}
            />
          </SecondImg>
        </Container>
      )}
    </IO>
  );
};

ResidencesIntro.propTypes = {
  title: PropTypes.string.isRequired,
  image1: PropTypes.object.isRequired,
  image2: PropTypes.object.isRequired,
};

export default ResidencesIntro;
