import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: black;
  display: block;
  img {
    transition-delay: 600ms;
    transition: opacity 2400ms;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  }
`;
