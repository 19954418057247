import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Container, Titleh1 } from './residences.scss.js';
import IO from 'components/io';
import Button from 'components/button';

const Residences = ({ title, floorplan, cta }) => {
  return (
    <IO rootMargin="-50px">
      {({ isVisible }) => (
        <Container isVisible={isVisible}>
          <div className="main-img">
            <Img
              isVisible={isVisible}
              fluid={floorplan ? floorplan.childImageSharp.fluid : {}}
              alt={title}
            />
          </div>
          <div className="bottom-row">
            <Titleh1 isVisible={isVisible}>{title}</Titleh1>
            <Button text={cta} url="/residences" />
          </div>
        </Container>
      )}
    </IO>
  );
};

Residences.propTypes = {
  title: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  floorplan: PropTypes.object.isRequired,
};

export default Residences;
