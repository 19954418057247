import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Container, Titleh1, BottomRow } from './quote.scss';
import IO from 'components/io';

const Quote = ({ title, quoted, logo, background, link }) => {
  return (
    <IO rootMargin="50px">
      {({ isVisible }) => (
        <Container isVisible={isVisible}>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <Img
              className="main-img"
              fluid={background ? background.childImageSharp.fluid : {}}
              alt={title}
            />
          </a>
          <BottomRow isVisible={isVisible}>
            <div className="mover">
              <a href={link} target="_blank" rel="noopener noreferrer">
                <Titleh1>{title}</Titleh1>
                <p>{quoted}</p>
                <img src={logo ? logo.publicURL : {}} alt={title} />
              </a>
            </div>
          </BottomRow>
        </Container>
      )}
    </IO>
  );
};

Quote.propTypes = {
  title: PropTypes.string.isRequired,
  quoted: PropTypes.string.isRequired,
  logo: PropTypes.object.isRequired,
  background: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
};

export default Quote;
