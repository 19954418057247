import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';

export const Container = styled.div`
  width: ${px2rem(274)};
  border: solid 2px #000000;
  display: block;
  text-align: center;
  line-height: ${px2rem(67)};
  text-transform: uppercase;
  font-size: ${px2rem(21)};
  color: #000000;
  margin: auto;
  margin-bottom: ${px2rem(120)};

  transition-delay: 1200ms;
  transition: opacity 2400ms;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  a {
    text-decoration: none;
    color: black;
    font-family: 'EngraversMT';
    display: block;
    transition: background-color 300ms, color 500ms;
    background-color: white;
    &:hover {
      transition: background-color 300ms, color 500ms;
      background-color: black;
      color: white;
    }
  }
`;
