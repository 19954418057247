import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Image1 = styled.div`
  position: absolute;
  top: 100px;
  left: 0;
  width: ${px2rem(650)};
  height: ${px2rem(480)};
  transition: opacity 2400ms, transform 2000ms;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: translateY(${({ isVisible }) => (isVisible ? '0%' : '-100%')});

  ${MEDIA.MEGA`
    transform: translateY(${({ isVisible }) => (isVisible ? '-58%' : '-100%')});
  `};

  ${MEDIA.WIDE`
    transform: translateY(${({ isVisible }) => (isVisible ? '-60%' : '-100%')});
  `};

  ${MEDIA.LAPTOP`
    width: ${px2rem(587)};
    height: ${px2rem(431)};
    transform: translateY(${({ isVisible }) => (isVisible ? '-58%' : '-100%')});
  `};

  ${MEDIA.DESKTOP`
    position: relative;
    height: unset;
    top: 50px;
    width: 65%;
    right: 0;
  `};
`;

export const Image2 = styled.div`
  position: absolute;
  top: ${px2rem(200)};
  z-index: 0;
  right: 0;
  width: ${px2rem(900)};
  height: ${px2rem(741)};
  transition: opacity 3200ms, transform 3000ms;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: translateY(${({ isVisible }) => (isVisible ? '0%' : '50%')});

  ${MEDIA.WIDE`
    right: ${px2rem(0)};
    top: ${px2rem(250)};
    width: ${px2rem(720)};
  `};

  ${MEDIA.LAPTOP`
    right: ${px2rem(-100)};
    top: ${px2rem(250)};
    width: ${px2rem(600)};
  `};

  ${MEDIA.DESKTOP`
    position: relative;
    top: 0;
    width: 100%;
    margin: auto;
    height: unset;
    max-width: ${px2rem(720)};
    max-height: ${px2rem(741)};
  `};
`;

export const Number = styled.div`
  width: ${px2rem(577)};
  height: ${px2rem(273)};
  font-size: ${px2rem(264)};
  line-height: 0.8;
  letter-spacing: normal;
  text-align: center;
  margin: auto;
  z-index: 100;

  ${MEDIA.DESKTOP`
    width: 100%;
  `};
`;

export const Describe = styled.div`
  width: ${px2rem(593)};
  font-size: ${px2rem(25)};
  line-height: 2.04;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin: auto;
  margin-bottom: 30px;
  z-index: 200;
  box-sizing: border-box;
  ${MEDIA.DESKTOP`
    width: 100%;
    height: unset;
    padding: 0 20px;
  `};
`;

export const Container = styled.div`
  position: relative;
  height: ${px2rem(941)};
  text-align: center;
  color: black;

  ${MEDIA.DESKTOP`
    height: unset;
    margin-bottom: 80px;
  `};
`;

export const DescribeContain = styled.div`
  width: ${px2rem(593)};
  height: ${px2rem(941)};
  position: absolute;
  z-index: 10;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;

  .getty-button {
    margin: 0;
    ${MEDIA.DESKTOP`
      margin-left: 20px;
    `};
  }

  ${MEDIA.DESKTOP`

    h1 {
      font-size: 14px;
    }

    position: relative;
    height: unset;
    margin-bottom: 20px;
  `};
`;
