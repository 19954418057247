import React from 'react';
import renderHTML from 'react-render-html';
import PropTypes from 'prop-types';
import IO from 'components/io';
import Img from 'gatsby-image';
import { Container, PressImg, PressContent } from './residencesPress1.scss.js';

const ResidencesPress1 = ({ quote, credit, logo, image, link }) => {
  return (
    <IO rootMargin="-50px">
      {({ isVisible }) => (
        <Container isVisible={isVisible}>
          <PressImg isVisible={isVisible}>
            <a href={link} target="_blank" rel="noopener noreferrer">
              <Img
                fluid={image ? image.childImageSharp.fluid : {}}
                alt={credit}
              />
            </a>
          </PressImg>
          <PressContent isVisible={isVisible}>
            <a href={link} target="_blank" rel="noopener noreferrer">
              <h1>
                {'“'}
                {quote}
                {'”'}
              </h1>
              <div className="row">
                <h2>{renderHTML(credit)}</h2>
                <img className="logo" src={logo.publicURL} alt={credit} />
              </div>
            </a>
          </PressContent>
        </Container>
      )}
    </IO>
  );
};

ResidencesPress1.propTypes = {
  quote: PropTypes.string.isRequired,
  credit: PropTypes.string.isRequired,
  logo: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
};

export default ResidencesPress1;
