import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  position: relative;
  margin-top: 128px;

  .big-img {
    top: 0;
    min-height: 400px;
    max-height: calc(100vh - 105px);
  }

  h1 {
    position: absolute;
    z-index: 100;
    top: 50%;
    padding: 0 30px;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
    max-width: ${px2rem(1400)};
    font-size: ${px2rem(60)};
    line-height: 1.39;
    color: #ffffff;
    transition: opacity 2000ms;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

    a {
      color: #fff;
      text-decoration: none;
    }

    ${MEDIA.TABLET`
      font-size: 22px;
    `};

    img {
      margin-top: 20px;
      width: 100px;
      ${MEDIA.TABLET`
        width: 70px;
      `};
    }
  }
`;
