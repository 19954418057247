import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';

export const LogoStyle = styled.div`
  width: ${px2rem(480)};
  svg {
    width: 100%;
    max-height: ${px2rem(100)};
    path {
      fill: white;
    }
  }
`;
