import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Navline = styled.div`
  position: fixed;
  width: 2px;
  height: 60px;
  background-color: black;
  top: 100px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 5000;

  opacity: 0;
  animation-fill-mode: forwards;
  animation-name: navFade;
  animation-duration: 1.2s;
  animation-delay: 3s;
  animation-timing-function: ease-in;

  @keyframes navFade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  ${MEDIA.DESKTOP`
    display: none;
  `};
`;
