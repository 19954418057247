import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Image1 = styled.div`
  width: ${px2rem(630)};
  height: ${px2rem(972)};
  left: 0;
  top: 0;
  position: absolute;
  transition: opacity 2400ms, transform 3000ms;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: translateY(${({ isVisible }) => (isVisible ? '0%' : '-20%')});

  ${MEDIA.DESKTOP`
    position: relative;
    margin: auto;
    width: 40%;
    height: unset;
    margin-top: 0;
    max-width: ${px2rem(630)};
    max-height: ${px2rem(972)};
  `};
`;

export const Image2 = styled.div`
  position: absolute;
  top: ${px2rem(-40)};
  right: 0;
  width: ${px2rem(902)};
  height: ${px2rem(1180)};
  transition: opacity 3200ms, transform 2200ms;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: translateY(${({ isVisible }) => (isVisible ? '0%' : '20%')});

  ${MEDIA.LAPTOP`
      max-width: ${px2rem(631)};
      max-height: ${px2rem(826)};
      top: ${px2rem(120)};
  `};

  ${MEDIA.DESKTOP`
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    margin-bottom: 40px;
    width: 70%;
    height: unset;
    max-width: ${px2rem(902)};
    max-height: ${px2rem(1180)};
  `};
`;

export const Number = styled.div`
  font-size: ${px2rem(100)};
  line-height: 0.8;
  letter-spacing: normal;
  text-align: center;
  margin: auto;
  transition: opacity 3200ms;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${MEDIA.LAPTOP`
    font-size: ${px2rem(90)};
  `};
`;

export const Describe = styled.div`
  max-width: ${px2rem(890)};
  font-size: ${px2rem(25)};
  line-height: 2.04;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin: auto;
  margin-top: ${px2rem(50)};
  z-index: 200;
  padding: 20px;
  box-sizing: border-box;
`;

export const Container = styled.div`
  position: relative;
  text-align: center;
  color: black;
  margin-top: 60px;
  width: 100%;
  height: ${px2rem(1040)};

  ${MEDIA.LAPTOP`
    height: ${px2rem(980)};
  `};

  ${MEDIA.DESKTOP`
    height: unset;
    display: flex;
    width: unset;
    margin-top: 120px;
    margin-bottom: 100px;
    flex-direction: column;
  `};
`;

export const DescribeContain = styled.div`
  max-width: ${px2rem(1167)};
  height: ${px2rem(941)};
  position: absolute;
  z-index: 100;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;

  ${MEDIA.DESKTOP`
    position: relative;
    height: unset;
  `};
`;
