import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const FormContainer = styled.div`
  transition: background-color 0.3s ease;
  position: relative;
  width: ${px2rem(900)};
  margin: auto;
  margin-top: 180px;
  margin-bottom: ${px2rem(120)};

  letter-spacing: normal;
  text-align: center;
  color: #000000;

  ${MEDIA.TABLET`
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
  `};

  a {
    color: #000000;
  }

  #form-broker {
    label {
      margin-top: 16px;
    }
  }

  .describer {
    margin: 0;
    padding: 0 ${px2rem(100)};
    font-size: ${px2rem(18)};
    line-height: 1.63;
    margin-bottom: ${px2rem(60)};

    p {
      margin-bottom: 6px;
    }

    ${MEDIA.DESKTOP`
      font-size: 14px;
      padding: 0;
    `};

    ${MEDIA.TABLET`
      font-size: 14px;
      padding: 0 30px;
    `};
  }

  h1 {
    font-size: ${px2rem(48)};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.63;
    margin-bottom: ${px2rem(40)};

    ${MEDIA.DESKTOP`
      font-size: 32px;
    `};
  }

  label {
    margin-top: 30px;
    text-align: left;
    font-size: 14px;
  }

  textarea {
    margin-top: 2px;
    width: ${px2rem(900)};
    font-size: ${px2rem(24)};
    padding: 0 ${px2rem(10)};
    box-sizing: border-box;
    border: 1px solid #d2d2d2;

    ${MEDIA.TABLET`
      width: 100%;
    `};
  }

  label.comments-label {
    width: 100%;
    display: block;
    text-align: left;
  }

  input.submitter {
    width: ${px2rem(274)};
    height: ${px2rem(67)};
    border: solid 2px #000000;
    text-align: center;
    font-family: 'EngraversMT';
    font-size: ${px2rem(21)};
    font-weight: normal;
    color: #000000;
    margin-top: ${px2rem(30)};
    background-color: white;
    cursor: pointer;
    transition: background-color 500ms, color 300ms;
    &:hover {
      background-color: black;
      color: white;
    }
  }

  .logo-img {
    margin-top: ${px2rem(100)};
  }
`;

export const FormRowAlternative = styled.div`
  display: flex;
  flex-direction: column;

  .main-label {
    margin-bottom: ${px2rem(16)};
  }

  fieldset {
    margin-bottom: ${px2rem(20)};
    display: block;
    text-align: left;

    ${MEDIA.TABLET`
      margin-bottom: 0;
    `};

    input {
      display: inline-block;
      width: unset;
      height: unset;
      border-radius: 0;

      ${MEDIA.TABLET`
        width: 100%;
      `};
    }
    label {
      display: inline-block;
      margin: 0 ${px2rem(30)} 0 ${px2rem(10)};
    }
  }
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${px2rem(20)};

  ${MEDIA.TABLET`
    flex-direction: column;
    margin-bottom: 0;
  `};

  label {
    display: block;
    text-align: left;
    margin-bottom: ${px2rem(22)};
  }

  input {
    box-sizing: border-box;
    width: ${px2rem(430)};
    height: ${px2rem(60)};
    font-size: ${px2rem(24)};
    padding: 0 ${px2rem(10)};
    border-radius: 0;

    ${MEDIA.TABLET`
      width: 100%;
    `};
  }

  div {
    width: 50%;
    ${MEDIA.TABLET`
      width: 100%;
    `};
  }

  div + div {
    margin-left: ${px2rem(40)};

    ${MEDIA.TABLET`
      margin-left: 0;
    `};
  }

  fieldset {
    display: block;
    text-align: left;
    input {
      display: inline-block;
      width: unset;
      height: unset;
    }
    label {
      text-align: left;
      display: inline-block;
      margin-left: 3px;
      margin-right: 20px;
    }
  }
`;
