import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import { FormContainer, FormRow } from 'styles/contact.scss.js';

import { Navline } from 'styles/utility.scss.js';
import renderHTML from 'react-render-html';

const Contact = ({ data }) => (
  <Layout>
    <Head pageTitle={data.contactJson.title} />
    <Navline />
    <FormContainer>
      <h1>{data.contactJson.title}</h1>
      <div className="describer">{renderHTML(data.contactJson.describe)}</div>
      {/* https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/#step-2 */}
      <form
        data-netlify="true"
        name="contact-form"
        method="post"
        action="/thanks"
        netlify-honeypot="bot-field"
      >
        <div hidden aria-hidden="true">
          <label>
            Don&apos;t fill this out if you&apos;re human:
            <input name="bot-field" />
          </label>
        </div>

        <input type="hidden" name="form-name" value="contact-form" />

        <FormRow>
          <div>
            <label htmlFor="f_name">First Name*</label>
            <input type="input" name="f_name" />
          </div>

          <div>
            <label htmlFor="l_name">Last Name*</label>
            <input type="input" name="l_Name" />
          </div>
        </FormRow>

        <FormRow>
          <div>
            <label htmlFor="email">Email Address*</label>
            <input type="input" name="email" />
          </div>

          <div>
            <label htmlFor="phone">Phone Number</label>
            <input type="input" name="phone" />
          </div>
        </FormRow>

        <FormRow>
          <div>
            <label htmlFor="broker" className="main-label">
              Are You A Broker?
            </label>
            <fieldset id="form-broker">
              <input type="radio" value="yes" name="broker" id="broker-yes" />
              <label htmlFor="broker-yes">Yes</label>
              <input type="radio" value="no" name="broker" id="broker-no" />
              <label htmlFor="broker-no">No</label>
            </fieldset>
          </div>

          <div>
            <label htmlFor="brokerage">Brokerage</label>
            <input type="input" name="brokerage" />
          </div>
        </FormRow>

        <br />

        <label className="comments-label" htmlFor="comments">
          Comments?
        </label>
        <br />

        <textarea name="comments" rows="8" cols="50"></textarea>

        <br />

        <input type="submit" value="Submit" className="submitter" />
      </form>

      <img
        width="218"
        style={{ marginTop: '60px' }}
        src={data.contactJson.logo.publicURL}
        alt="Reuveni"
      />
    </FormContainer>
  </Layout>
);

Contact.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Contact;

export const query = graphql`
  query ContactQuery {
    contactJson {
      title
      describe
      logo {
        publicURL
      }
    }
  }
`;
