import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  margin-top: 40px;
  margin-bottom: 120px;

  ${MEDIA.DESKTOP`
    flex-direction: column;
  `};

  h1 {
    width: ${px2rem(712)};
    font-size: ${px2rem(72)};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.39;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-left: ${px2rem(80)};
    margin-top: ${px2rem(30)};
    transition-delay: 600ms;
    transition: opacity 2400ms, transform 3000ms;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transform: translateX(${({ isVisible }) => (isVisible ? '0%' : '-40%')});

    ${MEDIA.DESKTOP`
      width: 100%;
      margin-left: 0;
      padding: 20px;
      box-sizing: border-box;
    `};
  }
`;

export const FirstImg = styled.div`
  width: ${px2rem(880)};

  transition: opacity 2400ms, transform 3000ms;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: translateY(${({ isVisible }) => (isVisible ? '0%' : '-20%')});

  ${MEDIA.DESKTOP`
    width: 100%;
    max-width: ${px2rem(880)};
    height: unset;
  `};
`;

export const SecondImg = styled.div`
  width: ${px2rem(936)};
  max-width: ${px2rem(936)};

  transition: opacity 2400ms, transform 3000ms;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: translateY(${({ isVisible }) => (isVisible ? '0%' : '10%')});

  ${MEDIA.DESKTOP`
    width: 80%;
    height: unset;
  `};
`;
