import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import {
  Container,
  TitleDescribeContain,
  Titleh1,
  AsideImg,
  Description,
} from './imageTitle.css';
import IO from 'components/io';

const ImageTitle = ({ hero, title, link, text }) => {
  return (
    <IO rootMargin="-10px">
      {({ isVisible }) => (
        <Container isVisible={isVisible}>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <div className="bottom-row">
              <TitleDescribeContain isVisible={isVisible}>
                <Titleh1>{title}</Titleh1>
                <Description>{text}</Description>
              </TitleDescribeContain>
              <AsideImg isVisible={isVisible}>
                <Img
                  fluid={hero ? hero.childImageSharp.fluid : {}}
                  alt={title}
                />
              </AsideImg>
            </div>
          </a>
        </Container>
      )}
    </IO>
  );
};

ImageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  hero: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
};

export default ImageTitle;
