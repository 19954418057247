import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Button from 'components/button';
import {
  Container,
  Image1,
  Image2,
  Number,
  Describe,
  DescribeContain,
} from './apt6.scss.js';
import IO from 'components/io';

const Apt6 = ({ image1, image2, text, number, label, floorplan }) => {
  return (
    <IO rootMargin="-50px">
      {({ isVisible }) => (
        <Container isVisible={isVisible}>
          <Image2 isVisible={isVisible}>
            <Img fluid={image2 ? image2.childImageSharp.fluid : {}} />
          </Image2>
          <Image1 isVisible={isVisible}>
            <Img fluid={image1 ? image1.childImageSharp.fluid : {}} />
          </Image1>
          <DescribeContain>
            <h1>{label}</h1>
            <Number>{number}</Number>
            <Describe>{text}</Describe>
            <Button text={'Floorplan'} url={floorplan} external />
          </DescribeContain>
        </Container>
      )}
    </IO>
  );
};

Apt6.propTypes = {
  image1: PropTypes.object.isRequired,
  image2: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  floorplan: PropTypes.string.isRequired,
};

export default Apt6;
