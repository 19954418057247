import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Caption = styled.div`
  position: absolute;
  z-index: 200;
  left: ${px2rem(120)};

  top: ${px2rem(497)};
  max-width: ${px2rem(740)};
  text-align: left;
  color: #ffffff;

  transition: transform 3000ms;
  transform: translateX(${({ isVisible }) => (isVisible ? '0%' : '-20%')});

  h1 {
    font-size: ${px2rem(72)};
    line-height: 1.39;
    ${MEDIA.TABLET`
      margin-bottom: 20px;
    `};
  }

  p {
    font-size: ${px2rem(25)};
    line-height: 2.04;

    ${MEDIA.TABLET`
      font-size: ${px2rem(27)};
      line-height: 1.65;
    `};
  }

  ${MEDIA.DESKTOP`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    margin-top: 30px;
    margin-left: 30px;
    top: 0;
    color: black;
    left: 0;
    padding: 0 20px;
  `};

  ${MEDIA.TABLET`
    margin-left: 0;
    h1 {
      font-size: ${px2rem(80)};
      line-height: 1.2;
    }
  `};
`;

export const Container = styled.div`
  position: relative;

  ${MEDIA.DESKTOP`
    height: unset;
  `};
  ${MEDIA.DESKTOP`
    margin-bottom: 120px;
  `};

  .main-img {
    width: 100%;
    max-width: ${px2rem(1475)};
    ${MEDIA.DESKTOP`
      max-width: 100%;
    `};
  }

  .bottom-row {
    ${MEDIA.DESKTOP`
      display: flex;
    `};
    ${MEDIA.TABLET`
      display: flex;
      flex-direction: column-reverse;
    `};
  }

  .aside-img {
    max-width: ${px2rem(789)};
    max-height: ${px2rem(526)};
    text-align: right;
    position: absolute;
    transition-delay: 1200ms;
    transition: transform 2000ms, opacity 2400ms;
    transform: translateY(${({ isVisible }) => (isVisible ? '-20%' : '-80%')});
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    right: 0;
    bottom: 0;

    ${MEDIA.LAPTOP`
      max-width: ${px2rem(500)};
      max-height: ${px2rem(463)};
    `};

    ${MEDIA.DESKTOP`
      position: relative;
      max-width: 50%;
      left: 20px;
      object-fit: cover;
      margin-top: 30px;
      top: 0;
      bottom: unset;
      transform: translateY(0);
    `};

    ${MEDIA.TABLET`
      max-width: calc(100% - 40px);
      max-height: unset;
    `};
  }
`;
