import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Image1Contain = styled.div`
  position: absolute;
  overflow: visible;
  z-index: 5;
  left: 0;
  top: ${px2rem(0)};
  max-width: ${px2rem(1285)};
  width: 70%;
  max-height: ${px2rem(852)};

  transition: opacity 2000ms, transform 2000ms;
  transform: translateY(${({ isVisible }) => (isVisible ? '0' : '-50px')});
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${MEDIA.DESKTOP`
      top: 0;
      left: 0;
      width: 90%;
      height: unset;
      position: relative;
      margin: auto;
  `};
`;
export const Image2Contain = styled.div`
  position: absolute;
  overflow: auto;
  z-index: 10;
  right: 0;
  top: ${px2rem(700)};
  max-width: ${px2rem(1059)};
  max-height: ${px2rem(716)};
  width: 50%;
  transition: opacity 2000ms;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${MEDIA.DESKTOP`
      top: 0;
      left: 0;
      width: 60%;
      height: unset;
      position: relative;
      margin: 30px auto;
  `};
`;

export const Quote = styled.div`
  color: black;
  font-size: ${px2rem(72)};
  line-height: 1.3;
  text-align: center;
  top: ${px2rem(900)};
  left: ${px2rem(100)};
  position: absolute;
  width: ${px2rem(480)};

  img {
    display: block;
    margin: auto;
    margin-top: ${px2rem(30)};
    max-width: ${px2rem(320)};
  }

  ${MEDIA.DESKTOP`
    position: relative;
    top: 0;
    left: 0;
    margin: auto;
    margin-top: 40px;
    font-size: ${px2rem(48)};
    padding-right: 10px;
  `};
`;

export const Caption = styled.div`
  position: relative;
  font-size: 12px;
  text-align: left;
  text-decoration: none;
  color: #000;
  margin-top: 5px;
  margin-left: 5px;
`;

export const Container = styled.div`
  position: relative;
  text-align: center;
  color: #fff;
  height: ${px2rem(1416)};

  a {
    text-decoration: none;
  }

  ${MEDIA.DESKTOP`
      height: unset;
  `};
`;
