import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './pageHeader.scss.js';
import IO from 'components/io';

const PageHeader = ({ children }) => {
  return (
    <IO rootMargin="-50px">
      {({ isVisible }) => (
        <Container isVisible={isVisible}>{children}</Container>
      )}
    </IO>
  );
};

PageHeader.propTypes = {
  children: PropTypes.object.isRequired,
};

export default PageHeader;
