import React from 'react';
import { Container, LogoAction } from './intro.scss.js';
import IO from 'components/io';
import Logo from 'components/logo';

const Intro = () => {
  return (
    <IO rootMargin="0px">
      {({ isVisible }) => (
        <Container isVisible={isVisible}>
          <LogoAction>
            <Logo />
          </LogoAction>
          <p></p>
        </Container>
      )}
    </IO>
  );
};

export default Intro;
