import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.nav`
  ul {
    position: absolute;
    top: 54px;
    display: flex;
    list-style: none;
    padding: 0;
    right: 65px;
    ${MEDIA.WIDE`
      right: 30px;
    `};

    .mobile-only {
      display: none;
      ${MEDIA.TABLET`
        display: block;
      `};
    }

    li {
      text-transform: uppercase;
      font-size: 15px;

      & + li {
        margin-left: 2rem;
        ${MEDIA.TABLET`
          margin-left: 0;
        `};
      }
    }
  }
`;
