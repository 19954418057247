import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  transform: translateY(-4%);
  margin-bottom: ${px2rem(160)};
  transition: opacity 3000ms ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  a {
    text-decoration: none;
  }

  ${MEDIA.DESKTOP`
    margin-top: 120px;
    flex-direction: column;
    transform: translateY(0);
  `};

  h1 {
    font-size: ${px2rem(72)};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.39;
    letter-spacing: normal;
    color: #000000;

    ${MEDIA.TABLET`
      font-size: 36px;
      margin-bottom: 20px;
      text-align: left;
    `};

    ${MEDIA.PHONE`
      font-size: 32px;
      margin-bottom: 20px;
      text-align: left;
    `};
  }
`;

export const PressImg = styled.div`
  width: ${px2rem(560)};
  height: ${px2rem(640)};
  margin-left: ${px2rem(100)};

  transition: opacity 1800ms;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${MEDIA.DESKTOP`
    margin-left: 0;
    max-width: 100%:
  `};
`;

export const PressContent = styled.div`
  position: absolute;
  width: ${px2rem(760)};
  text-align: center;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  z-index: 30;

  a {
    text-decoration: none;
    color: black;
  }

  ${MEDIA.DESKTOP`
    position: relative;
    transform: translateY(0);
    width: unset;
    text-align: left;
    padding: 20px;
    box-sizing: border-box;
  `};

  .row {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo {
    width: ${px2rem(214)};
    height: ${px2rem(77)};
    margin-left: 20px;
  }

  h2 {
    span {
      font-family: 'EngraversMT';
    }
  }
`;
