import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { Title, PressItemDetails } from './item.scss';

const PressItem = styled.div`
  background-image: url(${props => props.backgroundImage.src});
  background-size: cover;
  position: relative;
  background-position: center;
  overflow: auto;
  padding-bottom: 128%;
  &::before {
    content: '';
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 200;
  }
`;

const Item = ({ title, image, logo, link }) => (
  <PressItem backgroundImage={image.childImageSharp.fluid}>
    <a href={link} rel="noopener noreferrer" target="_blank">
      <PressItemDetails>
        <Img fluid={logo ? logo.childImageSharp.fluid : {}} alt={title} />
        <Title>{title}</Title>
      </PressItemDetails>
    </a>
  </PressItem>
);

Item.propTypes = {
  title: PropTypes.string,
  image: PropTypes.object.isRequired,
  logo: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
};

export default Item;
