import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Title = styled.span`
  display: block;
  position: absolute;
  bottom: ${px2rem(60)};
  left: ${px2rem(20)};
  right: ${px2rem(20)};
  color: #fff;
  font-size: ${px2rem(32)};
  text-align: center;
  padding: 0 20px;

  ${MEDIA.DESKTOP`
     font-size: ${px2rem(42)};
     padding: 0 12px;
  `};
`;

export const PressItemDetails = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 500;
  overflow: auto;

  .gatsby-image-wrapper {
    max-width: 180px;
    margin: auto;
    top: 40%;
    transform: translateY(-50%);
  }
`;
