import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  position: relative;

  .headline {
    text-align: center;
    font-size: ${px2rem(50)};
    margin-bottom: ${px2rem(120)};
    margin-top: ${px2rem(100)};
    font-family: 'EngraversMT';
    text-transform: uppercase;
    padding: 0 20px;
    line-height: 1.4;
    transition: opacity 2400ms, transform 3000ms;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transform: translateY(${({ isVisible }) => (isVisible ? '0%' : '-100%')});

    ${MEDIA.TABLET`
      padding: 0 16px;
      font-size: ${px2rem(40)};
      line-height: 1.3;
      text-transform: unset;
      margin-top: 70px;
      margin-bottom: 50px;
    `};
  }
`;

export const Locations = styled.div`
  max-width: ${px2rem(1200)};
  columns: 4;
  text-align: center;
  margin: auto;
  margin-bottom: 60px;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 2400ms;
  transition-delay: 600ms;

  ${MEDIA.DESKTOP`
    columns: 2;
  `};

  h1 {
    font-family: 'EngraversMT';
    font-size: ${px2rem(24)};
    text-transform: uppercase;
    line-height: 1.04;
    text-align: center;
    margin-bottom: 20px;

    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 3200ms;
    transition-delay: 600ms;
  }

  h1:nth-of-type(2n) {
    ${MEDIA.DESKTOP`
      margin-top: 60px;
    `};
  }

  h2 {
    font-size: ${px2rem(21)};
    line-height: 3;

    ${MEDIA.TABLET`
      font-size: 12px;
      line-height: 2.5;
    `};
  }
`;
