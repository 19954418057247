import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';

export const VideoContainer = styled.div`
  padding: 0;
  margin: 0;
  max-width: 1920px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 128px;

  video {
    width: 100%;
    height: calc(100vh - 128px);
    object-fit: cover;

    max-height: calc(100vw * 0.75);
  }

  h1 {
    display: none;
    position: absolute;
    text-align: center;
    font-family: 'GTSuper';
    font-size: ${px2rem(60)};
    line-height: 1.4;
    color: #ffffff;
    padding: 0 ${px2rem(100)};
    transition: opacity 2000ms;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  }

  @media screen and (min-width: 1920px) {
    video {
      width: 1920px;
    }

    h1 {
      width: ${px2rem(1440)};
      font-size: ${px2rem(72)};
    }
  }
`;
