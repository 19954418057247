import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Titleh1 = styled.h1`
  font-size: ${px2rem(72)};
  line-height: 1.4;
  ${MEDIA.DESKTOP`
    font-size: ${px2rem(48)};
    padding-right: 10px;
  `};
`;

export const BottomRow = styled.div`
  position: absolute;
  color: white;
  max-width: ${px2rem(1107)};
  text-align: left;
  top: 50%;
  transform: translateY(-50%);
  left: ${px2rem(120)};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 3200ms;
  z-index: 10;

  .mover {
    transition: transform 3000ms;
    transform: translateY(${({ isVisible }) => (isVisible ? '0%' : '-10%')});
  }

  ${MEDIA.DESKTOP`
        left: 20px;
    `};

  p {
    &::before {
      content: '– ';
    }
    font-family: 'EngraversMT';
    letter-spacing: 2px;
    text-align: left;
    font-size: ${px2rem(25)};
    line-height: 2.04;
    display: inline-block;
    transform: translateY(-37%);

    ${MEDIA.DESKTOP`
      margin-top: 30px;
    `};
  }

  img {
    margin-top: ${px2rem(30)};
    margin-left: ${px2rem(40)};
    max-width: ${px2rem(320)};
  }
`;

export const Container = styled.div`
  padding: 0;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 0;

  a {
    text-decoration: none;
    color: white;
  }

  .main-img {
    max-width: ${px2rem(2194)};
    height: ${px2rem(985)};
    object-fit: cover;
    object-position: center;

    ${MEDIA.TABLET`
      height: ${px2rem(540)};
    `};
  }
`;
