import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
// import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  position: relative;
  text-align: center;
  color: black;
  max-width: ${px2rem(1498)};
  font-size: ${px2rem(72)};
  line-height: 1.39;
  text-align: center;
  margin: auto;
  margin-bottom: 120px;
  padding: 0 20px;

  a {
    color: black;
    text-decoration: none;
  }

  img {
    width: ${({ logoSize }) => `${logoSize}px`};
    max-width: ${px2rem(400)};
    display: block;
    margin: 30px auto;
  }
`;
