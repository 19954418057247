import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Image1Contain = styled.div`
  position: absolute;
  z-index: 5;
  left: 0;
  width: ${px2rem(800)};
  height: ${px2rem(640)};
  object-fit: cover;
  transition: opacity 2000ms, transform 2000ms;
  transform: translateX(${({ isVisible }) => (isVisible ? '0' : '-50px')});
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${MEDIA.WIDE`
    width: ${px2rem(800)};
    height: ${px2rem(512)};
  `};

  ${MEDIA.DESKTOP`
      top: 0;
      left: 0;
      width: 50%;
      height: unset;
      position: relative;
      margin: auto;
  `};
`;

export const Image2Contain = styled.div`
  position: absolute;
  z-index: 20;
  top: ${px2rem(580)};
  left: ${px2rem(580)};
  width: ${px2rem(476)};
  height: ${px2rem(302)};
  object-fit: cover;

  transition: opacity 1500ms, transform 2000ms;
  transform: translateY(${({ isVisible }) => (isVisible ? '0' : '50px')});
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${MEDIA.WIDE`
    width: ${px2rem(380)};
    height: ${px2rem(241)};
    top: ${px2rem(464)};
    left: ${px2rem(464)};
  `};

  ${MEDIA.DESKTOP`
      top: 0;
      left: 0;
      width: 70%;
      height: unset;
      position: relative;
      margin: auto;
      margin-top: 20px;
  `};
`;

export const Image3Contain = styled.div`
  position: absolute;
  z-index: 5;
  left: ${px2rem(300)};
  top: ${px2rem(610)};
  width: ${px2rem(332)};
  height: ${px2rem(458)};

  transition: opacity 1500ms;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${MEDIA.WIDE`
    width: ${px2rem(280)};
    height: ${px2rem(380)};
    top: ${px2rem(510)};
    left: ${px2rem(240)};
  `};

  ${MEDIA.DESKTOP`
      top: 0;
      left: 0;
      width: 20%;
      height: unset;
      position: relative;
      margin: auto;
      margin-top: 20px;
  `};
`;

export const Container = styled.div`
  position: relative;
  text-align: center;
  color: #fff;
  height: ${px2rem(1068)};
  transform: translateY(-150px);

  ${MEDIA.WIDE`
    height: ${px2rem(854)};
  `};

  ${MEDIA.DESKTOP`
    height: unset;
    transform: translateY(0);
  `};

  a {
    text-decoration: none;
  }

  .caption {
    top: 200px;
    position: absolute;
    right: 0;
    width: 50vw;
    height: 336px;
    font-size: ${px2rem(72)};
    line-height: 1.39;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    max-width: 500px;
    right: 200px;

    ${MEDIA.WIDE`
      right: 50px;
    `};

    ${MEDIA.DESKTOP`
      top: 0;
      right: 0;
      width: 100%;
      padding: 50px;
      height: unset;
      box-sizing: border-box;
      position: relative;
      margin: auto;
    `};

    img {
      width: 120px;
      display: block;
      margin: 30px auto;
    }
  }
`;
