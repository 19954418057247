import React from 'react';
import { Container } from './footer.css';
import LogoLight from 'components/logoLight';
import IO from 'components/io';

import VictorLogo from '../../../content/images/victor-logo.svg';

const Footer = () => (
  <IO rootMargin="50px">
    {({ isVisible }) => (
      <Container isVisible={isVisible}>
        <a href="/" className="logo-link">
          <LogoLight isVisible={isVisible} />
        </a>

        <h2>&nbsp;</h2>

        <a href="/contact">
          <h3>Contact</h3>
        </a>

        <p>
          All renderings and images are for illustrative purposes only. Sponsor
          reserves the right to make substitutions of materials, equipment,
          fixtures and finishes in accordance with the terms of the Offering
          Plan. The complete offering terms are in an offering plan available
          from the Sponsor. File No. CD15-0212. Sponsor: VHS 239, LLC, c/o 230
          Park Avenue, New York, New York 10169. Equal Housing Opportunity.
          <br />
          <br />
          Getty Residences, Getty Residences by Marino, The Getty by Marino and
          other Getty marks are trademarks of VHS 239, LLC which is not
          affiliated or associated with, and is not endorsed or sponsored by,
          the J. Paul Getty Trust or the Getty museums.
          <br />
          <br />
          <a className="privacy-link" href="/privacy">
            Privacy Policy
          </a>
        </p>

        <div className="logo-row">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.victorgroupnyc.com/"
          >
            <VictorLogo style={{ height: '26px' }} />
          </a>
        </div>
      </Container>
    )}
  </IO>
);

export default Footer;
