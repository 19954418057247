import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Image1Contain = styled.div`
  position: absolute;
  overflow: auto;
  z-index: 10;
  left: ${px2rem(60)};
  top: ${px2rem(200)};
  width: ${px2rem(536)};
  height: ${px2rem(736)};

  transition: opacity 1500ms, transform 2000ms;
  transform: translateY(${({ isVisible }) => (isVisible ? '0' : '50px')});
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${MEDIA.DESKTOP`
      top: 0;
      left: 0;
      width: 50%;
      height: unset;
      position: relative;
      margin: auto;
      margin-top: 20px;
  `};
`;

export const Image2Contain = styled.div`
  position: absolute;
  z-index: 10;
  top: ${px2rem(950)};
  left: ${px2rem(200)};
  width: ${px2rem(752)};
  height: ${px2rem(1053)};

  transition: opacity 1500ms, transform 2000ms;
  transform: translateY(${({ isVisible }) => (isVisible ? '0' : '-20px')});
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${MEDIA.DESKTOP`
      top: 0;
      left: 0;
      width: 70%;
      height: unset;
      position: relative;
      margin: auto;
      margin-top: 20px;
  `};
`;

export const Image3Contain = styled.div`
  position: absolute;
  z-index: 5;
  top: ${px2rem(1050)};
  left: ${px2rem(970)};
  width: ${px2rem(385)};
  height: ${px2rem(539)};

  transition: opacity 1500ms, transform 2000ms;
  transform: translateY(${({ isVisible }) => (isVisible ? '0' : '-50px')});
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${MEDIA.DESKTOP`
      top: 0;
      left: 0;
      width: 50%;
      height: unset;
      position: relative;
      margin: auto;
      margin-top: 20px;
  `};
`;

export const Caption = styled.div`
  position: relative;
  font-size: 12px;
  text-align: right;
  text-decoration: none;
  color: #000;
  margin-bottom: 15px;
  margin-right: 0;
`;

export const Container = styled.div`
  transform: translateY(-35%);
  position: relative;
  text-align: center;
  color: #fff;
  height: ${px2rem(1440)};

  a {
    text-decoration: none;
  }

  ${MEDIA.DESKTOP`
    margin-top: 60px;
    height: unset;
    transform: translateY(0);
  `};
`;
