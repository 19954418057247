import React from 'react';
import PropTypes from 'prop-types';
import IO from 'components/io';
import { Container, PressContent } from './residencesPress2.scss.js';

const ResidencesPress2 = ({ quote, logo, link, shortTop }) => {
  const extraClass = shortTop ? 'short-top' : '';

  return (
    <IO rootMargin="-50px">
      {({ isVisible }) => (
        <Container isVisible={isVisible} className={extraClass}>
          <PressContent isVisible={isVisible}>
            <a href={link} target="_blank" rel="noopener noreferrer">
              <h1>
                {'“'}
                {quote}
                {'”'}
              </h1>
              <img className="logo" src={logo.publicURL} alt={quote} />
            </a>
          </PressContent>
        </Container>
      )}
    </IO>
  );
};

ResidencesPress2.propTypes = {
  quote: PropTypes.string.isRequired,
  logo: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
  shortTop: PropTypes.bool,
};

export default ResidencesPress2;
