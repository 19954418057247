import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Image1 = styled.div`
  max-width: ${px2rem(1500)};
  height: ${px2rem(813)};

  transition: opacity 1800ms, transform 2000ms;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  transform: translateX(${({ isVisible }) => (isVisible ? '0%' : '-20%')});

  margin-top: ${px2rem(640)};

  ${MEDIA.DESKTOP`
    width: 100%;
    height: unset;
    max-width: ${px2rem(1500)};
    max-height: ${px2rem(813)};
    margin-top: 30px;
    margin-bottom: 40px;
  `};
`;

export const Image2 = styled.div`
  position: absolute;
  z-index: 0;
  top: ${px2rem(1030)};
  right: 0;
  transition: opacity 1800ms, transform 2000ms;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: translateX(${({ isVisible }) => (isVisible ? '0%' : '10%')});
  z-index: 50;
  width: ${px2rem(801)};
  height: ${px2rem(607)};

  ${MEDIA.DESKTOP`
    top: -120px;
    left: 0;
    right: 0;
    margin: auto;
    margin-right: 0;
    width: 50%;
    min-width: 240px;
    height: unset;
    margin-bottom: 20px;
    max-width: ${px2rem(1500)};
    max-height: ${px2rem(813)};
  `};
`;

export const Number = styled.div`
  width: ${px2rem(577)};
  height: ${px2rem(273)};
  font-size: ${px2rem(264)};
  line-height: 0.8;
  letter-spacing: normal;
  text-align: center;
  margin: auto;
  z-index: 100;
`;

export const Describe = styled.div`
  width: ${px2rem(593)};
  font-size: ${px2rem(25)};
  line-height: 2.04;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin: auto;
  margin-bottom: 30px;
  z-index: 200;
`;

export const Container = styled.div`
  position: relative;
  text-align: center;
  color: black;
  margin-top: 20px;
  overflow-x: hidden;
  height: ${px2rem(1641)};

  ${MEDIA.DESKTOP`
    padding-top: 0;
    display: flex;
    height: unset;
    flex-direction: column;
    overflow: unset;
    margin-top: 180px;
    margin-bottom: 100px;
  `};
`;

export const DescribeContain = styled.div`
  width: ${px2rem(593)};
  height: ${px2rem(941)};
  position: absolute;
  z-index: 100;
  margin: auto;
  top: 70px;
  left: 0;
  right: 0;
  .getty-button {
    margin: 0;
  }

  ${MEDIA.DESKTOP`
    h1 {
        font-size: 14px;
        margin-bottom: 20px;
    }
    position: relative;
    height: unset;
    top: 0;
    margin-bottom: 30px;
  `};
`;
