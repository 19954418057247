import React from 'react';
import { LogoStyle } from './logo.css';

const Logo = () => (
  <LogoStyle>
    <svg
      id="gettyLogo"
      xmlns="http://www.w3.org/2000/svg"
      width="1267"
      height="228.89"
      viewBox="0 0 1267 228.89"
    >
      <g>
        <path d="M944.19,48.88c5.65-.3,11.3-.76,16.95-.83,3.93,0,7.87.43,11.81.7a35.74,35.74,0,0,1,4,.46c1.51.28,2,1,2.07,2.56.09,3.84-1.88,5.72-5.68,5.27a124.55,124.55,0,0,0-20.25-.73c-5.37.22-10.74.5-16.1.8a2.52,2.52,0,0,0-1.68.52,3.41,3.41,0,0,0-.73,2.17c.11,1,1.11,1,1.95,1,8.76.15,17.52.14,26.27.56a76.47,76.47,0,0,1,11.63,2,2.57,2.57,0,0,1,1.43,1.75,5.19,5.19,0,0,1-1.57,4.68,2.75,2.75,0,0,1-2.67.79,68.93,68.93,0,0,0-14.33-1.31c-6.8.07-13.6.18-20.4.24-1.11,0-1.57.52-1.53,1.56,0,.57.1,1.14.15,1.71.1,1.12.76,1.6,1.83,1.76,2.9.43,5.78,1,8.69,1.36a32.4,32.4,0,0,0,4.62.31c6.47,0,13-.28,19.42-.14,5,.11,10.08.61,15.11,1.06,2.93.26,4.28,3.52,2.6,6.11a4.19,4.19,0,0,1-4.49,2.18c-10.52-1.57-21.11-1.55-31.71-1.13-5.9.23-11.79.49-17.7.67-3,.09-6,.07-8.85-1.3a6.22,6.22,0,0,1-3.21-5.26,3.09,3.09,0,0,1,2.09-2.51,14.25,14.25,0,0,1,2.66-.86,2.17,2.17,0,0,0,1.94-2.38c-.19-3.74-.39-7.48-.58-11.22a6.83,6.83,0,0,0-2.42-4.85,11.62,11.62,0,0,1-2.13-3.87,1.73,1.73,0,0,1,.45-1.42,5.45,5.45,0,0,1,4.11-2c5.42-.15,10.83-.47,16.24-.72Z" />
        <path d="M157.13,88.47c-2.41,0-4.81.07-7.21,0a7,7,0,0,1-5.3-2.37c-2.33-2.65-2.49-5.22-.42-8.08.33-.46.7-.89,1-1.36a6.33,6.33,0,0,0,1.33-3.94c-.05-5.82,0-11.64.12-17.47a30.81,30.81,0,0,1,.54-4.47c.27-1.67,1.53-2.55,2.93-3.2a1.37,1.37,0,0,1,2,.84c.77,2.27,2.39,2.62,4.51,2.45,6.82-.53,13.64-1.09,20.47-1.39,6.3-.28,12.61-.27,18.92-.32a2.88,2.88,0,0,1,2.89,2.64A3.47,3.47,0,0,1,195,56.16c-2-.2-4.07-.77-6.06-.61-10.95.86-21.9,1.89-32.84,2.86l-.24,0c-2.64.42-2.64.42-3,3-.08.61-.17,1.21-.27,1.81-.24,1.5.3,2.28,1.82,2.12a54.83,54.83,0,0,0,5.49-1.07c.48-.1.94-.29,1.41-.36,5.32-.75,10.64-1.54,16-2.22,4-.52,8.09-1,12.15-1.29a14.59,14.59,0,0,1,4.2.41,2.07,2.07,0,0,1,1.53,2.35c-.1,3.2-1.35,4.26-4.67,5-4,.86-8.12,1.29-12.19,1.92-5.79.89-11.61,1.63-17.11,3.82-2.35.94-4.73,1.82-6.63,3.56-.42.4-1,1.17-.9,1.48a2.28,2.28,0,0,0,1.53,1.16,35.06,35.06,0,0,0,6,.34c5.49-.32,11-.86,16.45-1.2q9.69-.6,19.4-1a50.51,50.51,0,0,1,5.48.33,2.09,2.09,0,0,1,.91.3c2.07,1.32,2,5.88-.16,7a4.07,4.07,0,0,1-2.17,0,19.44,19.44,0,0,0-2.94-.55c-5.21.14-10.43.26-15.63.63-5.43.38-10.84,1.07-16.26,1.56-3,.27-6.1.41-9.14.61Z" />
        <path d="M355,62.91c3.42,0,6.84,0,10.27,0a46.05,46.05,0,0,1,12.4,1.84,2.39,2.39,0,0,1,1.42,1.46,5,5,0,0,1-1.25,4.64,2.73,2.73,0,0,1-2.64.91,73.05,73.05,0,0,0-15.7-1c-5.86.21-11.72.38-17.58.56-1.3,0-1.66.39-1.57,1.71,0,.53.13,1.05.2,1.58.12,1,.72,1.36,1.64,1.49,2.94.41,5.87,1,8.82,1.23a81.82,81.82,0,0,0,10.62,0c8.89-.6,17.75,0,26.61.49a3.71,3.71,0,0,1,2.63,6,4.19,4.19,0,0,1-4.33,2.06c-10.06-1.34-20.15-1.15-30.25-.58-5.78.33-11.55.69-17.32,1-2.86.13-5.7.1-8.39-1.16a5.88,5.88,0,0,1-3.11-5,2.78,2.78,0,0,1,1.78-2.31,16.66,16.66,0,0,1,2.76-1,2,2,0,0,0,1.74-2.28c-.22-3.7-.47-7.4-.73-11.1a6.61,6.61,0,0,0-2.28-4.29,12.35,12.35,0,0,1-2.19-3.68c-.17-.37.15-1.13.47-1.52a4.94,4.94,0,0,1,3.79-1.9q13.06-.53,26.12-1.19c2.15-.11,4.3-.62,6.44-.6,3.46,0,6.91.32,10.36.51,1,.06,1.95.11,2.93.19,3.18.27,4.32,2.24,3,5.19a4,4,0,0,1-4.12,2.64c-1.55-.06-3.1-.17-4.64-.32a117.67,117.67,0,0,0-20.73.33c-3.53.28-7.08.36-10.61.63a2.86,2.86,0,0,0-1.84.92,2,2,0,0,0-.26,1.77,2.06,2.06,0,0,0,1.58.8c4.64.06,9.29,0,13.93,0Z" />
        <path d="M848.94,83.18c.17-3.42.47-6.31.41-9.2-.06-2.63-.52-5.26-.82-7.88-.2-1.76-.41-3.51-.65-5.26a13.46,13.46,0,0,0-.57-3.06,12,12,0,0,0-1.59-2.3c-.05-.06-.14-.11-.16-.18-.34-1.13-1.28-2-1.69-2.95a13.36,13.36,0,0,1-.52-5,3.23,3.23,0,0,1,.75-2.14,3.64,3.64,0,0,1,2.23-1c4.3-.15,8.6-.28,12.89-.17,3,.08,6,.52,9,.83,1.64.17,3.29.36,4.91.64,2.24.39,4.45.91,6.68,1.33,1.42.27,2.85.45,4.27.72,4,.78,7.9,2.26,11.86,3.28,2.92.74,5.53,2.08,8.3,3.06A13.25,13.25,0,0,1,910.77,59a12,12,0,0,1,1.81,9.06c-.44,2.72-2.18,4.46-4,6.25a1.56,1.56,0,0,1-.52.24C905,75.7,902,76.93,898.88,78s-6.55,2-9.87,2.82c-2.28.54-4.67.62-7,1.14a58.57,58.57,0,0,1-9.71,1.2c-3,.17-6,.56-9,1-.92.14-1.73.9-2.64,1.24a5.13,5.13,0,0,1-2.05.33c-.61,0-1.32-.68-1.77-.5a7.83,7.83,0,0,0-2.17,1.63c-.31.28-.44.75-.71,1.08-1.13,1.36-2.52,1.21-3.6-.18S849.33,84.36,848.94,83.18Zm5.95-6.54a14.35,14.35,0,0,1,1.9-.12,63.24,63.24,0,0,0,12.14-.83c2.34-.29,4.67-.58,7-.89,2.86-.38,5.74-.66,8.57-1.2s5.55-1.25,8.29-2c1.75-.49,3.4-1.34,5.15-1.83a26.65,26.65,0,0,0,7.32-3.39c1.62-1.06,1.87-1.61.62-2.53a19.56,19.56,0,0,0-3.63-2.21c-2.54-1.11-5.15-2.09-7.77-3.06a34.46,34.46,0,0,0-3.76-1.09c-2.1-.52-4.22-.94-6.32-1.45s-3.89-1.23-5.88-1.53c-3.46-.51-6.95-.87-10.43-1.11-3.32-.23-6.66-.3-10-.31-1.38,0-2.8-.46-4.12.87-.66.67-.67.91-.3,1.49,1,1.63,1.22,3.21-.12,4.78a1,1,0,0,0-.2.66c.07,1,.19,2,.27,3,.19,2.18,1,4.27.86,6.52A55.59,55.59,0,0,0,854.89,76.64Z" />
        <path d="M1128.69,74.47c4-.26,8.22-.56,12.49-.82,7.84-.46,15.68-.94,23.53-1.32a40.29,40.29,0,0,1,5.48.32,2.34,2.34,0,0,1,1.28.6,4.67,4.67,0,0,1,0,6,2.14,2.14,0,0,1-2.56.47,6.6,6.6,0,0,0-2.69-.57c-5.29.13-10.59.23-15.87.6-5.06.35-10.1,1.11-15.17,1.5s-10.16.67-15.24.8c-3.79.09-7.11-3-6.94-6.41a10.45,10.45,0,0,1,1.26-3.48,6.92,6.92,0,0,1,1-1.39,6,6,0,0,0,1.35-4c-.08-5.34,0-10.67.07-16a36,36,0,0,1,.51-4.73,4.21,4.21,0,0,1,2.81-3.13,1.36,1.36,0,0,1,2,.87c.69,2,2.09,2.41,4,2.26,7.83-.59,15.67-1.21,23.51-1.59,4.79-.23,9.61-.09,14.41,0,1.94,0,3.14,1.66,2.93,3.68s-1.55,3.43-3.56,3c-4.31-.85-8.58-.44-12.88,0-8.42.82-16.86,1.49-25.29,2.3-2.23.22-2.2.36-2.43,2.53-.08.68-.2,1.37-.31,2.05-.2,1.27.33,2.1,1.6,2,1.81-.18,3.59-.65,5.38-1,.51-.11,1-.31,1.53-.39,4.84-.68,9.67-1.4,14.52-2q6.42-.81,12.87-1.38a10.54,10.54,0,0,1,3.47.43,2.07,2.07,0,0,1,1.5,2.26c0,2.67-1.4,4.25-4.57,4.82-3.92.69-7.89,1.17-11.83,1.8-5.46.87-11,1.5-16.15,3.67a57.56,57.56,0,0,0-6.52,3.49c-.4.23-.87,1-.75,1.32a2,2,0,0,0,1.3,1.08A35.9,35.9,0,0,0,1128.69,74.47Z" />
        <path d="M707.55,78.87c4-.26,8.2-.57,12.42-.82,7.72-.46,15.44-.93,23.17-1.31a39.17,39.17,0,0,1,5.72.31,2.75,2.75,0,0,1,1.55.81,4.77,4.77,0,0,1,0,5.68,2.16,2.16,0,0,1-2.74.59,5.81,5.81,0,0,0-2.45-.56c-5.41.15-10.83.29-16.23.61-3.85.23-7.68.81-11.53,1.1-6.13.47-12.26.9-18.4,1.21a6.8,6.8,0,0,1-6.67-3.9,4.66,4.66,0,0,1-.06-4.78c.75-1.3,1.71-2.48,2.44-3.79a4.72,4.72,0,0,0,.58-2.17c.07-5.54,0-11.08.11-16.62a39.9,39.9,0,0,1,.44-4.49,4.36,4.36,0,0,1,2.7-3.38,1.45,1.45,0,0,1,2.09.62,12.15,12.15,0,0,1,.64,1.18,2,2,0,0,0,2.15,1.26c.9-.05,1.8,0,2.69-.08,7.39-.51,14.78-1.16,22.17-1.51,4.8-.23,9.61-.09,14.41,0a3.05,3.05,0,0,1,2.89,3.6c-.18,2.18-1.53,3.52-3.48,3.13-4.39-.87-8.74-.44-13.12,0-8.31.8-16.63,1.45-24.93,2.26-2.4.23-2.38.38-2.65,2.72-.07.61-.18,1.21-.28,1.81-.23,1.41.32,2.2,1.76,2.06a48.22,48.22,0,0,0,5-1c.83-.17,1.65-.42,2.49-.54,4.59-.67,9.18-1.36,13.79-1.93,4.28-.54,8.58-1,12.87-1.4a9.09,9.09,0,0,1,1.95.05c1.27.13,2.56.47,2.86,1.86a3.8,3.8,0,0,1-1.2,4.11,8.73,8.73,0,0,1-3.41,1.52c-3.89.7-7.81,1.17-11.71,1.79-5.46.88-11,1.51-16.15,3.68A55.84,55.84,0,0,0,703,76c-.42.24-.93,1.09-.8,1.39a2.29,2.29,0,0,0,1.46,1.08A34.13,34.13,0,0,0,707.55,78.87Z" />
        <path d="M685.14,91.72c-2.72-2.22-6-3.17-9-4.53-2.33-1-4.57-2.25-6.88-3.32-1.41-.66-2.87-1.18-4.3-1.78-2-.83-4-1.69-6-2.51-1-.43-2.13-.7-3.1-1.22a40.94,40.94,0,0,0-8.32-2.8c-2-.55-3.89-1.28-5.86-1.83a23.16,23.16,0,0,0-3.11-.5c-1.16-.16-2.35-.19-3.48-.46a32.79,32.79,0,0,1-4.46-1.36c-.47-.18-.78-.82-1.12-1.27-.79-1-1.11-.73-1.37-2-.11-.53-.15-.64-.68-.51l-.67-.19a.56.56,0,0,0-.43.52c-.08,2-.34,2.73-.4,4.9,0,1.55,0,3.09,0,4.64s.06,3.22.17,4.82a2.46,2.46,0,0,0,.53,1.34c1.34,1.69,1.08,3.49.48,5.33-.44,1.33-2.85,1.6-3.79.5A18.34,18.34,0,0,1,621,86c-1.3-2.55-.86-5.34-1-8-.06-1.6-.19-3.19-.2-4.78,0-3,.08-6.09.07-9.13,0-2.48-.08-5-.18-7.43a6.44,6.44,0,0,0-.45-1.5,1,1,0,0,1-.09-.35c.18-2.3.31-4.61.58-6.91.18-1.48.47-1.4,1.86-1.07a19.59,19.59,0,0,0,5.77.2c3.47-.61,6.88-1.6,10.44-1.66,2.07,0,4.14-.39,6.2-.3a53.57,53.57,0,0,1,6.66.81c3.58.61,7.18,1.28,10.39,3.07a51.46,51.46,0,0,1,5.89,4,3.84,3.84,0,0,1,1.41,2c.42,1.87,1.32,3.63,1.07,5.68a3.66,3.66,0,0,1-1.84,2.67,24.46,24.46,0,0,1-9,3.76c-1.44.29-2.85.78-4.29,1.07-1.81.38-3.64.64-5.63,1l1.19.52c5.73,2.37,11.49,4.67,17.18,7.12,4.52,2,9,4.05,13.44,6.16,1.8.85,3.74,1.55,5,3.23.09.12.3.16.37.29C686.37,87.09,685.78,91,685.14,91.72ZM627.26,64.49c1.12-1.66,1.12-1.69,2.73-1.64.5,0,1,.37,1.48.35,2.17-.07,4.33-.16,6.49-.37,3.39-.33,6.8-.57,10.14-1.2,3.81-.71,7.58-1.73,11.33-2.76A38.35,38.35,0,0,0,664,56.93a23.08,23.08,0,0,0-3-1.64c-1.59-.56-3.26-.87-4.85-1.4a33.82,33.82,0,0,0-10.93-1.59,16.61,16.61,0,0,0-2.74.13c-2,.35-4.07.86-6.11,1.21s-2.06.29-2.44,2.46a3.87,3.87,0,0,1-.47,1.71,2,2,0,0,1-1.53.77,37.57,37.57,0,0,1-3.72-1.33,13.79,13.79,0,0,0-1.63-.37C626.82,59.57,627,62,627.26,64.49Z" />
        <path d="M1226.39,76.85a18.3,18.3,0,0,0-2-1.49c-3.58-2-7.13-4.14-10.8-6-2.91-1.49-5.93-2.76-9-4-2.18-.89-4.46-1.52-6.65-2.37-1.94-.75-3.84-1.63-5.75-2.5a47.6,47.6,0,0,1-4.42-2.17,22.42,22.42,0,0,1-3.32-2.43,3.73,3.73,0,0,1-1.44-3.42c.13-1.3,0-2.65.65-3.8,1.06-1.83,2.21-3.56,4.31-4.52a32.52,32.52,0,0,1,8.94-2.57,57.78,57.78,0,0,1,10.24-.79c1.4.06,2.8.06,4.19.16,2.15.15,4.29.42,6.44.54a4.18,4.18,0,0,1,3.51,2c.31.51-.1,1.48-.23,2.24a14.63,14.63,0,0,0-.39,2,3.38,3.38,0,0,1-2.59,2.88c-1.33.39-2.66.78-4,1.06a1.71,1.71,0,0,1-1.26-.42,4.46,4.46,0,0,1-1-1.36,2.26,2.26,0,0,0-2.14-1.29c-3.11.1-6.23.3-9.34.49-1.7.1-3.41.15-5.09.4s-3.33.71-5,1.08l-.13.42c.85.49,1.66,1.05,2.54,1.45,3.18,1.42,6.35,2.86,9.58,4.17,2.25.92,4.62,1.55,6.87,2.47s4.64,2.16,7,3.2c2.11,1,4.33,1.67,6.35,2.78,2.22,1.21,4.25,2.76,6.38,4.13a15.23,15.23,0,0,1,5,5.94,5.45,5.45,0,0,1,.64,4.17,41.43,41.43,0,0,1-1.64,4.9c-.57,1.28-1.52,1.47-2.94,1.33-1.8-.18-3.62-.1-5.42-.27-2.95-.27-5.89-.69-8.84-1-2.27-.22-4.57-.25-6.83-.55-2.41-.33-4.78-.89-7.18-1.32-.87-.16-1.76-.2-2.62-.36-1.93-.34-3.85-.75-5.78-1.09-2.54-.46-5.07-.93-7.62-1.32a2.69,2.69,0,0,1-2.45-3.21,13.58,13.58,0,0,1,.86-3.85,14.66,14.66,0,0,1,2.65-3.22c.18-.2.75,0,1.14,0a29.49,29.49,0,0,1,3.53.17c1.53.24,3.28-.38,4.5,1.11.15.18.45.38.43.54-.19,1.59,1,1.9,2.09,2.16,3.5.79,7,1.69,10.51,2.26s7.1.75,10.66,1.14c1.44.16,2.87.46,4.31.62a19.44,19.44,0,0,0,2.29,0Z" />
        <path d="M802.43,78.73a17.14,17.14,0,0,0-1.88-1.21c-4.19-2-8.36-4.09-12.61-6a77.69,77.69,0,0,0-7.57-2.75c-2.94-1-5.92-1.77-8.88-2.68-.8-.25-1.59-.58-2.37-.91-2.52-1.08-5.09-2.07-7.53-3.3a15.94,15.94,0,0,1-2.66-2.38c-1-.88-.42-2.06-.4-3,.05-2.68,2.12-4.42,4.08-5.55a23.94,23.94,0,0,1,7.32-2.27,66.46,66.46,0,0,1,7.6-.88c1.64-.15,3.3-.16,5-.17,2.06,0,4.13,0,6.19,0a19.38,19.38,0,0,1,2.13.27c1.68.24,3.38.37,5,.76.67.16,1.21.9,1.77,1.35a20.77,20.77,0,0,1-.94,4,4.14,4.14,0,0,1-2.08,1.82c-1.23.51-2.67.5-3.91,1-1.49.61-2.18-.36-2.77-1.25a2.71,2.71,0,0,0-2.64-1.22c-2.36.05-4.74,0-7.09.13-4.1.31-8.25.26-12.34,1.72a3,3,0,0,0,.66.48c2.87,1.12,5.74,2.26,8.64,3.31s5.94,2.06,8.9,3.11c2.72,1,5.42,2,8.13,3a45,45,0,0,1,15,7.52,13.6,13.6,0,0,1,3.45,4.66c1.07,2.08-.59,6.25-2,7.26a3.74,3.74,0,0,1-2,.63c-1.22,0-2.43-.24-3.65-.32-2.36-.16-4.73-.22-7.09-.42-3.3-.28-6.59-.62-9.88-1q-6.15-.64-12.29-1.37c-1.43-.18-2.85-.54-4.28-.78-2.61-.43-5.21-.89-7.83-1.22-1.16-.15-2.2-.39-2.71-1.52a3.4,3.4,0,0,1-.2-1.56,7.47,7.47,0,0,1,3.19-5.5,2.6,2.6,0,0,1,1.52-.33,22.11,22.11,0,0,1,3.55.15c1.47.23,3.12-.45,4.38.81a3.33,3.33,0,0,1,.72,1.1,1.57,1.57,0,0,0,1.42,1.14c3.73.62,7.43,1.5,11.16,2.12,2.27.38,4.6.43,6.89.66,2.87.3,5.74.65,8.61.92a14.83,14.83,0,0,0,2.2-.12Z" />
        <path d="M1048.69,66.49a36.27,36.27,0,0,1,1-6,12.26,12.26,0,0,1,3.26-5.67c1.76-1.49,3.49-3,5.31-4.45a15.17,15.17,0,0,1,2.44-1.26c2-1,4-2.2,6.15-3.11a24,24,0,0,1,4.37-1.23c2.3-.52,4.6-1,6.93-1.39,3-.45,6.13-.85,8.93.74a8,8,0,0,1,2.34,2.77,9.87,9.87,0,0,1,1.56,4.69,4.41,4.41,0,0,1-.15,1.3c-.31,1.21.16,2.51-.87,3.69a2,2,0,0,1-2.4.79,2.65,2.65,0,0,1-1.3-1.81c-.19-1.71-.83-2.9-2.59-3.21a7.87,7.87,0,0,0-1.71.17,35.94,35.94,0,0,0-3.75.18,31.48,31.48,0,0,0-6.13,1.27,75.7,75.7,0,0,0-9.82,4.38,25.65,25.65,0,0,0-5.35,4.38,11.06,11.06,0,0,0-1.62,3c-.29.55-.88,1.18-.78,1.66a7.05,7.05,0,0,0,1.3,3.4,13.36,13.36,0,0,0,8,3.7,46.35,46.35,0,0,0,6,.77c3.82.06,7.65-.07,11.47-.21,2.14-.08,4.27-.41,6.41-.52,1.61-.09,3.23.06,4.84,0,3-.14,5.94-.36,8.9-.59a4.21,4.21,0,0,1,2.39,0c1.58.66,1.65,1.93,2,3.17.06.23,0,.53.06.72A2,2,0,0,1,1106,80c-.6.88-1.19,1.76-1.85,2.59-.12.16-.53.12-.81.11-1.59,0-3.17-.1-4.75,0-1.14,0-2.27.41-3.41.5-1.53.13-3.07.14-4.61.19-1.77.06-3.54.11-5.31.18q-3.6.17-7.2.36c-1.21.06-2.42.24-3.62.19-1.65-.08-3.28-.37-4.93-.47-1-.07-2.07.15-3.08,0-1.77-.22-3.53-.59-5.28-1-.78-.16-1.53-.45-2.3-.68a1.74,1.74,0,0,1-.46-.13c-1.46-.86-2.92-1.71-4.35-2.62-.28-.17-.42-.56-.64-.83a26.7,26.7,0,0,1-4-7.12A14.69,14.69,0,0,1,1048.69,66.49Z" />
        <path d="M289.38,84.7q-10.14.27-20.28.56a25.29,25.29,0,0,1-10.82-2.12A6,6,0,0,1,255,79.72a11.07,11.07,0,0,1,.64-9.54,47.21,47.21,0,0,1,8.12-11.83,28.6,28.6,0,0,1,19.47-8.75c5.28-.31,10.57-.4,15.86-.39a19.08,19.08,0,0,1,5.13,1,3.5,3.5,0,0,1,2.54,3,4,4,0,0,1-1.61,3.87,3,3,0,0,1-2.3.38c-5.34-1.34-10.75-1.23-16.15-.77A63.28,63.28,0,0,0,276,58.39c-5,1.29-8.62,4.65-11.44,8.85a62.72,62.72,0,0,0-3.47,6c-.77,1.48-.39,2.22,1.18,2.82a30.3,30.3,0,0,0,12.28,1.54c4.19-.18,8.38-.52,12.57-.51,6.43,0,12.86.35,19.29.42A65.4,65.4,0,0,0,314,77c2.06-.22,3.37.19,3.85,1.5.95,2.53.48,4.26-1.46,5.42A8.37,8.37,0,0,1,311.73,85c-4.84-.15-9.69-.3-14.53-.41-2.61-.06-5.22,0-7.82,0Z" />
        <path d="M124.3,63.29c-.14-2.27-.4-5.28-.45-8.29a3.52,3.52,0,0,1,1-2.48,4.67,4.67,0,0,1,2.86-.91c1,0,1.29,1.13,1.4,2,.58,4.39,1.09,8.8,1.71,13.18.27,1.89.69,3.76,1.13,5.62.33,1.43,1.59,1.8,2.77,2.3a17.9,17.9,0,0,1,3.85,2c1.83,1.41,1.81,4.61.16,6.22a2.66,2.66,0,0,1-2.57.78,2.09,2.09,0,0,0-2.44,1.14,2.88,2.88,0,0,1-4.66.22c-.86-1.14-1.57-2.39-2.49-3.47a3.33,3.33,0,0,0-1.87-1.14c-7.16-.92-14.33-1.66-21.56-.86-3.76.41-7.52.9-11.28,1.35-.48.06-1,.13-1.45.16-1.9.1-2.21.43-2.7,2.32-.46,1.72-1.06,3.41-1.63,5.1a3.35,3.35,0,0,1-.64,1c-1.15,1.3-2.23,1.32-3-.24a54.27,54.27,0,0,1-2.2-6.16c-.48-1.45-.75-3-1.33-4.38A2.24,2.24,0,0,1,79.29,76a8.21,8.21,0,0,0,2.22-5c.73-5.16,1.61-10.29,2.42-15.44.12-.72.16-1.46.26-2.18A4.34,4.34,0,0,1,87,49.93a1.2,1.2,0,0,1,1.84.81A18.63,18.63,0,0,1,89.56,56c-.33,3.77-1,7.5-1.59,11.25-.2,1.37-.36,2.74-.51,4.12s.39,1.79,1.69,1.73c7-.3,13.92-.74,20.88-.85,4.34-.06,8.69.37,13,.61,1.49.08,1.7-.08,1.63-1.61C124.58,68.83,124.46,66.43,124.3,63.29Z" />
        <path d="M583.94,43.27A6.46,6.46,0,0,1,582.1,47a34.9,34.9,0,0,1-10.29,7.53,34.86,34.86,0,0,0-10.43,7.33,2.08,2.08,0,0,0-.56,2.18A4,4,0,0,1,559,68.26a4.54,4.54,0,0,0-2.07,4.57c.29,4.71.57,9.43.73,14.14a7,7,0,0,1-.8,3.1,2.79,2.79,0,0,1-3.15,1.33c-1.2-.22-1.51-1.14-1.67-2.14-.51-3.25-1-6.5-1.46-9.77s-.76-6.87-1.2-10.3a3.15,3.15,0,0,0-2.23-2.81c-4.17-1.26-8.31-2.6-12.53-3.62-5.53-1.35-11.14-2.38-16.69-3.65a9.4,9.4,0,0,1-4.76-2.61,6.88,6.88,0,0,1-1.79-4.8,2.28,2.28,0,0,1,2.73-2.28,20.23,20.23,0,0,1,4.29.91,154.11,154.11,0,0,0,16.1,4.47c5.36,1.16,10.64,2.7,15.95,4.07a6.23,6.23,0,0,0,1.77.45,3.54,3.54,0,0,0,2.16-.68c4-4.25,9-7,14.13-9.66,3-1.61,5.69-3.9,8.5-5.89a17.3,17.3,0,0,1,2.89-2,3.51,3.51,0,0,1,2.68,0C583.22,41.4,583.49,42.5,583.94,43.27Z" />
        <path d="M1006.17,60.3a48.56,48.56,0,0,1-.3,6.28c-.48,2.83-1.28,5.6-2,8.38-.28,1-.85,2-1.12,3-.4,1.59-.55,3.24-1,4.8a3.69,3.69,0,0,1-1.9,2.68,5.16,5.16,0,0,0-1.47-.89c-1.54-.32-1.84-1.48-2.07-2.74,0-.08,0-.18,0-.24-1.69-2.23.09-4.25.43-6.33.39-2.36,1-4.68,1.54-7,.31-1.4.57-2.8.87-4.2.54-2.54,1.2-5.06,1.62-7.62a40.29,40.29,0,0,0,.24-5,4.39,4.39,0,0,1,2.78-3.88c1.41-.53,2.1.44,2.93,1.23a23.41,23.41,0,0,0,3.07,2.75,4,4,0,0,1,2,3.21c0,.75.38,1.4-.14,2.24-.21.34.66,1.48,1.2,2.12,1.39,1.64,2.85,3.21,4.31,4.78a18.07,18.07,0,0,0,1.56,1.34c1.6,1.37,3.15,2.81,4.82,4.08a46.55,46.55,0,0,0,4.13,2.63c1.33.81,2.66,1.62,4,2.36a13.28,13.28,0,0,0,1.94.72,20.46,20.46,0,0,0,2.29-8.85c.05-1.8,0-3.61,0-5.41a1.74,1.74,0,0,0-1.19-1.7c-.75-.37-1.22-1.5-1.63-2.38a31.57,31.57,0,0,1-1.78-4.59,25.32,25.32,0,0,1-.53-3.81,11.47,11.47,0,0,1-.28-2.59,5.06,5.06,0,0,1,.83-2.64c.56-.68,1.39-1.61,2.61-1,.23.13.58.22.67.42,1.12,2.49,2.07,5.07,3.33,7.49,1.74,3.33,2.16,7,3.08,10.56a15.19,15.19,0,0,1,.36,3.93q0,3.61-.29,7.22a35.27,35.27,0,0,1-.66,5.23,51.4,51.4,0,0,1-1.78,5.61c-.24.68.23,1.68-.9,2a3.75,3.75,0,0,1-3.33-.62c-1.38-.89-2.94-1.48-4.38-2.28A48.53,48.53,0,0,1,1025.8,79c-1.82-1.27-3.61-2.61-5.35-4s-3.26-2.67-4.78-4.12a63.18,63.18,0,0,1-7.09-8.23c-.62-.83-1.33-1.59-2-2.38Z" />
        <path d="M446.29,57.07a8.58,8.58,0,0,1-.07,1c-.37,2.29-2.29,3.23-4.83,2.6a60.88,60.88,0,0,0-7.87-1.57c-3.23-.36-6.5-.35-9.76-.5a1.14,1.14,0,0,0-1.35,1.22,17.29,17.29,0,0,0,.37,4.21,57.08,57.08,0,0,1,1.85,11.68c.16,2.15.45,4.29.64,6.44a24,24,0,0,1,.14,3.28A3.35,3.35,0,0,1,423,88.28a1.44,1.44,0,0,1-1.11-.25,6.12,6.12,0,0,1-2.38-3.72c-.5-3.38-1.15-6.75-1.47-10.15-.46-5-2.17-9.67-3.89-14.33A2.69,2.69,0,0,0,411.9,58a12.27,12.27,0,0,0-2.18-.13c-5.32.36-10.64.76-16,1.15-1.05.08-2.1.23-3.16.27-2.27.07-3.24-1.54-3.8-3.75-.34-1.38,1.08-3.33,2.66-3.78a13,13,0,0,1,2.4-.45c4.46-.38,8.92-.93,13.38-1,5-.13,9.93,0,14.88.3,5.53.3,11.05.74,16.56,1.35a57.32,57.32,0,0,1,7.73,1.69C445.88,54,446.32,54.9,446.29,57.07Z" />
        <path d="M489.24,49.54l15.38.62c.57,0,1.14.07,1.71.07,2.1,0,2.39.4,2.73,2.51s-.67,3.49-2.09,4.75a3,3,0,0,1-3.21.58c-3.39-1.24-6.91-1-10.39-1-4.32,0-8.63.27-12.95.43-1.54.06-1.94.54-1.69,2.09q1.5,9.35,3,18.69a3.47,3.47,0,0,0,1.63,2.59c1.32.83,1.48,1.49,1.15,3-.55,2.58-2.38,4-4.55,5.08-.84.42-1.77,0-2.14-1.21a68.41,68.41,0,0,1-1.8-7.07c-.91-4.59-1.7-9.2-2.51-13.82-.4-2.32-.76-4.66-1.06-7-.2-1.58-.36-1.66-1.91-1.42-5.63.87-11.27,1.65-16.9,2.51-3.2.49-4.17-2-4.44-4.39a3.12,3.12,0,0,1,2.65-2.94c4.3-.64,8.59-1.4,12.92-1.84C472.87,50.92,481,50.28,489.24,49.54Z" />
        <path d="M28.69,52.53a375.23,375.23,0,0,1,38.77-1.85c3.25,0,6.51.23,9.77.33a1.67,1.67,0,0,1,1.59,1.27,4.26,4.26,0,0,1-3.7,5.92c-1.58.09-3.17-.07-4.76-.09-5-.06-9.93-.14-14.89-.15a2.37,2.37,0,0,0-2.61,2.36c-.61,4.76-1.18,9.53-1.78,14.29-.15,1.17-.36,2.34-.57,3.5a6.91,6.91,0,0,0,.82,4.35A3.81,3.81,0,0,1,51.79,85a21.73,21.73,0,0,1-2.4,4.61c-.66,1-1.43.89-2.23,0a8.47,8.47,0,0,1-1.89-4.94A23.88,23.88,0,0,1,45.2,80c.63-6.36,1.36-12.7,2-19.06.22-2.11-.36-2.62-2.48-2.46-3.53.26-7.07.44-10.61.7-3,.21-5.91.55-8.87.69-1.45.06-2.92-.23-4.38-.3-1.92-.11-3-1.32-3.81-2.85-.94-1.79-.2-3.17,2.09-3.93a9.49,9.49,0,0,1,2.78-.25C24.62,52.51,27.31,52.53,28.69,52.53Z" />
        <path d="M286.71,63.55c1.37.29,2.82.66,4.29.89a23,23,0,0,0,3.51.31c5.25,0,10.5-.08,15.75-.06a28,28,0,0,1,3.38.47,12.87,12.87,0,0,0,2.54.25c2.7-.21,4.31,1.48,3.87,4.17-.47,2.87-1.75,4-4.6,3.47-8.2-1.38-16.41-.68-24.63-.46a59.55,59.55,0,0,1-7.44-.08,15.19,15.19,0,0,1-4.61-1.41,3.76,3.76,0,0,1-2-4c.24-1.51,1-2.2,3-2.56C282,64.15,284.3,63.89,286.71,63.55Z" />
        <path d="M830.36,49.43c0,4.89.07,8.92,0,12.94-.06,2.71-.45,5.42-.48,8.13,0,2.88.15,5.76.3,8.64.05,1.06.79,1.54,2,1.44,2.26-.2,2.94.65,2,2.66a25.69,25.69,0,0,1-2.24,3.28c-.14.19-.43.35-.44.54-.09,1-.9,1-1.51,1-.9-.06-2.15-.11-2.59-.68-.83-1.06-2.52-2.45-2.92-3.77a13,13,0,0,1-.18-2.74c-.15-1.79-.39-3.58-.48-5.38,0-1,.19-2.08.2-3.13,0-2.73,0-5.46,0-8.19,0-1.25.31-2.49.34-3.74a33.7,33.7,0,0,0-.17-4,1.23,1.23,0,0,0-.62-.91c-1.42-.41-1.29-1.66-1.43-2.64s.33-2.23,0-3.18c-.66-1.95.71-3.1,1.38-4.53.49-1,3.88-1.38,4.64-.55C829.58,46.21,829.82,48.39,830.36,49.43Z" />
        <path d="M426.84,200.25c3.62-.25,4.55-1.28,4.55-5.13l0-20.3c0-4-1-4.88-4.57-5.11v-1.18l14.38,0c5.21,0,10.57,2.47,10.58,8.58,0,6.48-5.81,9.12-10.93,9.13h-5l0,8.88c0,4.23,1.18,4.88,6,5.11v1.17l-15,0Zm13.27-15.63c4.51,0,6.94-2.55,6.94-7.57,0-4.47-1.9-7.05-6.55-7h-4.7l0,14.61Z" />
        <path d="M484.85,191l-1,10.34-26.31.05v-1.18c3.62-.15,4.56-1.28,4.55-5.13l0-20.29c0-4-1-5-4.57-5.12v-1.17l25.61,0,1,9.4h-1.45c-1.42-5.68-4.11-7.79-10.64-7.78h-5.49l0,13.11H470c4.37,0,5.45-1.37,6-5.65h1.22l0,13.11H476c-.57-4.46-1.74-5.78-6-5.77h-3.53l0,11.79c0,2,.66,3.1,3.15,3.1h3c6.68,0,9.11-2.32,10.75-8.76Z" />
        <path d="M518.07,167.43l0,11h-1.41c-.67-5.87-2.13-8.17-6.12-8.16h-4l.05,24.66c0,4.23,1.18,4.89,6,5.11v1.18l-16.4,0v-1.18c4.93-.24,6-1,6-5.13l0-24.67h-4c-4.14,0-5.54,2.46-6.14,8.19h-1.41l0-11h1.17c.43.7,1,.93,2.31.93l20.62,0c1.22,0,1.88-.29,2.26-.95Z" />
        <path d="M551,190.86l-1,10.34-26.31.05v-1.18c3.62-.15,4.55-1.27,4.55-5.13l0-20.29c0-4-1-5-4.57-5.12v-1.17l25.61-.05,1,9.4h-1.46c-1.42-5.68-4.1-7.79-10.63-7.78h-5.5l0,13.11h3.52c4.37,0,5.45-1.37,6-5.65h1.22l0,13.11h-1.22c-.57-4.46-1.75-5.78-6-5.77h-3.53l0,11.79c0,2,.67,3.1,3.16,3.1h3c6.67,0,9.11-2.32,10.75-8.76Z" />
        <path d="M557.88,200c3.62-.24,4.56-1.28,4.55-5.13l0-20.3c0-4-1-4.88-4.56-5.11V168.3l14.43,0c5.63,0,10.52,2.1,10.54,7.73s-4.17,8.14-9.67,8.24l-1.74,0v.28l1.74.05c4.28.09,5.46,1.92,7.86,6.56l2.78,5.4c1.32,2.54,3,3.42,5.22,3.42v1.13h-7.75a18.61,18.61,0,0,1-2.4-4.13l-2.26-4.64c-1.84-3.81-3.21-7.19-7-7.18h-2.72v9.67c0,4,1,4.89,4.57,5.11v1.18l-13.58,0Zm12.61-16.42c6.06,0,7.56-3.4,7.55-7.53,0-4.32-2.46-6.2-7.39-6.19h-3.8l0,13.72Z" />
        <path d="M617.47,179a33.44,33.44,0,0,1-2.18-6.53h-.19c0,2.12,0,4.23-.12,6.35l-.77,15.5c-.23,4.84.47,5.21,4.94,5.63v1.17l-12.12,0V200c4-.43,4.74-1.09,5-5.7l1.19-20.62c.18-3.29-1-4-4.43-4.32v-1.12l9.26,0,8.82,21.55a24,24,0,0,1,1.7,5.16h.24a35.2,35.2,0,0,1,2-5.45l9.12-21.3,8.93,0v1.13c-3.62.34-4.74,1.23-4.6,4.38l1.22,21.09c.19,4.18,1.18,4.74,5.08,5.16V201l-14,0v-1.17c4-.34,4.8-1,4.55-5.13l-1-16.26c-.1-1.87-.15-3.75-.11-5.63h-.18a51.61,51.61,0,0,1-2.06,5.31l-9.92,23.13H627Z" />
        <path d="M688.17,199.78V201l-12.64,0v-1.22c3.81-.1,4.79-.95,3.52-4.37l-2.41-6.58-12.26,0-2.25,6.26c-1.35,3.71-.27,4.55,3.2,4.69V201l-10.43,0v-1.18c2.77-.28,3.56-1.5,4.87-5l9.59-26.05,4-1.65,10.48,28.13C685.16,198.75,686.67,199.54,688.17,199.78ZM676,187.11l-2.69-7.42c-1.56-4.27-2.64-7.37-2.69-8.82h-.24c0,1.45-1.26,4.79-2.75,8.88L665,187.13Z" />
        <path d="M693.2,199.77c3.62-.24,4.55-1.28,4.55-5.13l0-20.3c0-4-1-4.88-4.57-5.11v-1.18l14.42,0c5.64,0,10.53,2.09,10.54,7.73s-4.16,8.14-9.66,8.24l-1.74.05v.28l1.74,0c4.27.08,5.45,1.91,7.86,6.56l2.78,5.4c1.32,2.53,3,3.42,5.22,3.42v1.12l-7.75,0a18.61,18.61,0,0,1-2.4-4.13l-2.27-4.65c-1.84-3.8-3.2-7.18-7-7.17h-2.73l0,9.68c0,4,1,4.88,4.57,5.11v1.18l-13.58,0Zm12.61-16.42c6.06,0,7.55-3.4,7.55-7.53,0-4.33-2.46-6.2-7.39-6.19h-3.81l0,13.72Z" />
        <path d="M738.34,194.56c0,4,1,4.89,4.57,5.12v1.17l-13.58,0V199.7c3.62-.24,4.56-1.28,4.55-5.13l0-20.29c0-4-1-4.89-4.57-5.12V168l13.58,0v1.18c-3.76.33-4.55,1-4.55,5.13Z" />
        <path d="M758.23,175.64a15.44,15.44,0,0,1-2.17-3.8l-.19,0a28.73,28.73,0,0,1,.34,4.84l0,18.6c.06,3.48,1,3.85,5,4.31v1.18l-11.61,0v-1.18c3.62-.24,4.56-1.27,4.55-5.12l0-24c-1.27-1.12-2-1.21-4.56-1.44V168l9,0,15.69,20.55a25.47,25.47,0,0,1,3.25,5.26l.23-.1c-.14-1.69-.19-3.19-.2-5.35l0-14.38c0-3.94-.9-4.41-4.94-4.88v-1.17l11.61,0v1.17c-3.76.34-4.55,1.05-4.55,5.13l0,26.83h-1.88Z" />
        <path d="M789.68,184.32c0-10,7.2-17.25,17.3-17.27s17.35,7.2,17.37,17.21-7.2,17.26-17.3,17.28S789.7,194.33,789.68,184.32Zm29.27,0c0-9.11-4.68-14.93-12-14.92s-11.92,5.85-11.91,15,4.68,14.93,12,14.92S819,193.38,819,184.27Z" />
        <path d="M625.19,129.55c0,4.81-3,8-7.27,8a9.9,9.9,0,0,1-4.71-1.18l0-15.91c0-1.52-.41-1.79-1.91-1.68v-.22l4.6-1.26v8.51a4.81,4.81,0,0,1,4.32-2.7C623.14,123.11,625.18,125.55,625.19,129.55Zm-2.91.95c0-3.94-1.26-6-3.66-6a2.89,2.89,0,0,0-2.74,1.83l0,9.46a2.86,2.86,0,0,0,2.5,1.25C620.74,137,622.29,134.77,622.28,130.5Z" />
        <path d="M626.1,142.46l.46-2.64h.14a3.93,3.93,0,0,0,2.45.76,3.69,3.69,0,0,0,3.77-2.29l.22-.49-4.62-11.58c-.6-1.57-1.28-2.39-2.26-2.49v-.22h6.55v.21c-1.27.11-1.71.47-1.71,1.23a4.27,4.27,0,0,0,.33,1.39l2.93,8,3.14-7.76a4.05,4.05,0,0,0,.29-1.33c0-.9-.6-1.39-1.63-1.49v-.22h4.16v.22c-1,.3-1.47.85-2.09,2.56l-4.71,11.92c-1.43,4-3.34,4.79-5.24,4.79A4.38,4.38,0,0,1,626.1,142.46Z" />
      </g>
    </svg>
  </LogoStyle>
);

export default Logo;
