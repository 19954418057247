import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  position: relative;
  margin-top: ${px2rem(200)};
  padding-left: ${px2rem(75)};
  transition: opacity 3000ms ease;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  &.short-top {
    ${MEDIA.DESKTOP`
    margin-top: 20px;
  `};
  }

  a {
    text-decoration: none;
  }

  ${MEDIA.DESKTOP`
    margin: auto;
    padding: 0 20px;
    margin-top: ${px2rem(100)};
  `};

  h1 {
    font-size: ${px2rem(72)};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.39;
    letter-spacing: normal;
    color: #000000;

    ${MEDIA.TABLET`
      font-size: 32px;
    `};
  }
`;

export const PressContent = styled.div`
  width: ${px2rem(760)};
  text-align: left;

  ${MEDIA.DESKTOP`
    margin: auto;
    max-width: 100%;
    margin-bottom: ${px2rem(100)};
  `};

  .logo {
    transition: opacity 1800ms;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    width: ${px2rem(214)};
    height: ${px2rem(77)};

    ${MEDIA.DESKTOP`
      margin-top: 14px;
      transform: scale(1.3);
      transform-origin: top left;
    `};
  }
`;
