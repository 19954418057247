import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: auto;
  grid-gap: 4rem;
  padding: 0 4rem;
  margin: 4rem auto;
  max-width: 1200px;

  ${MEDIA.DESKTOP`
    grid-template-columns: repeat(2, 1fr);
  `};

  ${MEDIA.TABLET`
    grid-template-columns: repeat(1, 1fr);
  `};
`;
