import React from 'react';
import PropTypes from 'prop-types';
import Item from './item';
import { Container } from './pressGallery.css';

const PressGallery = ({ items }) => (
  <Container>
    {items.map((item, i) => (
      <Item {...item} key={i} />
    ))}
  </Container>
);

PressGallery.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PressGallery;
