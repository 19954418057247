import React from 'react';
import { Container } from './nav.css';
// import { Link } from 'gatsby';

const Nav = () => (
  <Container>
    <ul>
      <li>
        <a href="/residences">Residences</a>
      </li>
      <li>
        <a href="/works">Works</a>
      </li>
      <li>
        <a href="/press">Press</a>
      </li>
      <li className="mobile-only">
        <a href="/contact">Contact</a>
      </li>
    </ul>
  </Container>
);

export default Nav;
