import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import {
  Container,
  Image1,
  Image2,
  Number,
  Describe,
  DescribeContain,
} from './aptPH.scss.js';
import IO from 'components/io';

const AptPH = ({ image1, image2, text, number }) => {
  return (
    <IO rootMargin="-50px">
      {({ isVisible }) => (
        <Container isVisible={isVisible}>
          <Image2 isVisible={isVisible}>
            <Img fluid={image2 ? image2.childImageSharp.fluid : {}} />
          </Image2>
          <DescribeContain isVisible={isVisible}>
            <Number isVisible={isVisible}>{number}</Number>
            <Describe>{text}</Describe>
          </DescribeContain>
          <Image1 isVisible={isVisible}>
            <Img fluid={image1 ? image1.childImageSharp.fluid : {}} />
          </Image1>
        </Container>
      )}
    </IO>
  );
};

AptPH.propTypes = {
  image1: PropTypes.object.isRequired,
  image2: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
};

export default AptPH;
