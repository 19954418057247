import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Image1 = styled.div`
  position: absolute;
  bottom: -100px;
  right: ${px2rem(180)};
  width: ${px2rem(446)};
  height: ${px2rem(366)};
  z-index: 10;
  transition: opacity 2400ms, transform 2000ms;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: translateY(${({ isVisible }) => (isVisible ? '-40%' : '-70%')});

  ${MEDIA.MEGA`
    transform: translateY(${({ isVisible }) => (isVisible ? '-70%' : '-100%')});
  `};

  ${MEDIA.WIDE`
    transform: translateY(${({ isVisible }) => (isVisible ? '-70%' : '-100%')});
  `};

  ${MEDIA.LAPTOP`
    transform: translateY(${({ isVisible }) => (isVisible ? '-40%' : '-70%')});
  `};

  ${MEDIA.DESKTOP`
    transform: translateY(${({ isVisible }) => (isVisible ? '-40%' : '-40%')});
    position: relative;
    margin: auto;
    width: 100%;
    max-width: ${px2rem(520)};

    height: unset;
    bottom: 0;
    right: 0;
  `};
`;

export const Image2 = styled.div`
  position: absolute;
  top: ${px2rem(200)};
  z-index: 0;
  right: 0;
  width: ${px2rem(760)};
  height: ${px2rem(802)};

  transition: opacity 2400ms, transform 2000ms;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: translateY(${({ isVisible }) => (isVisible ? '-10%' : '-40%')});

  ${MEDIA.MEGA`
    transform: translateY(${({ isVisible }) => (isVisible ? '-10%' : '-40%')});
    width: ${px2rem(720)};
    right: ${px2rem(60)};
  `};

  ${MEDIA.WIDE`
    transform: translateY(${({ isVisible }) => (isVisible ? '-9%' : '-30%')});
    width: ${px2rem(640)};
    right: ${px2rem(60)};
  `};

  ${MEDIA.LAPTOP`
    right: 0;
    width: ${px2rem(720)};
    transform: translateY(${({ isVisible }) => (isVisible ? '-42%' : '-60%')});
  `};

  ${MEDIA.DESKTOP`
    max-width: ${px2rem(960)};
    width: 100%;
    position: relative;
    margin: 30px auto;
    top: 0;
    bottom: 0;
    height: unset;
    transform: translateY(${({ isVisible }) => (isVisible ? '0%' : '-50%')});
  `};
`;

export const Number = styled.div`
  width: ${px2rem(577)};
  height: ${px2rem(273)};
  font-size: ${px2rem(264)};
  line-height: 0.8;
  letter-spacing: normal;
  text-align: center;
  margin: auto;
  z-index: 100;
`;

export const Describe = styled.div`
  width: ${px2rem(593)};
  font-size: ${px2rem(25)};
  line-height: 2.04;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin: auto;
  margin-bottom: 30px;
  z-index: 200;

  ${MEDIA.DESKTOP`
    width: unset;
    margin: auto;
    bottom: 0;
    height: unset;
    padding: 20px;
    box-sizing: border-box;
  `};
`;

export const Container = styled.div`
  position: relative;
  height: ${px2rem(980)};
  text-align: center;
  color: black;

  ${MEDIA.DESKTOP`
    overflow-y: unset;
    margin-top: 100px;
    height: unset;
  `};
`;

export const DescribeContain = styled.div`
  width: ${px2rem(593)};
  height: ${px2rem(941)};
  position: absolute;
  z-index: 10;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;

  .getty-button {
    margin: 0;
    ${MEDIA.DESKTOP`
      margin-left: 20px;
    `};
  }

  ${MEDIA.DESKTOP`

    transform: translateY(-95px);

    h1 {
      font-size: 14px;
      margin-bottom: 20px;
    }

    position: relative;
    margin: auto;
    margin-top: 40px;
    height: unset;
  `};
`;
