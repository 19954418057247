import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  position: relative;
  font-size: ${px2rem(60)};
  font-weight: normal;
  line-height: 1.63;
  margin: auto;
  margin-top: 100px;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  max-width: ${px2rem(1373)};
  padding: 0 ${px2rem(100)};

  transition: opacity 2400ms;
  transition-delay: 600ms;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  a {
    color: #000000;
    text-decoration: none;
  }

  ${MEDIA.DESKTOP`
    margin-top: 60px;
    font-size: ${px2rem(48)};
    padding: 0 10px;
  `};

  ${MEDIA.TABLET`
    font-size: ${px2rem(60)};
    line-height: 1.3;
    max-width: 100%;
    width: 100%;
    padding: 0;
    margin: auto;
    box-sizing: border-box;
    text-align: left;
  `};

  p {
    margin: 0;
    padding: 0 ${px2rem(100)};

    ${MEDIA.TABLET`
      padding: 0 14px;
    `};

    &::after {
      content: '';
      display: block;
      width: ${px2rem(100)};
      height: 2px;
      margin: auto;
      margin-top: ${px2rem(60)};
      background-color: black;
      ${MEDIA.TABLET`
        display: none;
      `};
    }
  }

  .logo-img {
    margin-top: ${px2rem(55)};
    width: ${px2rem(240)};
    ${MEDIA.TABLET`
        margin-top: 30px;
        width: ${px2rem(320)};
        margin-left: 10px;
    `};
  }
`;
