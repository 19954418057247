import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Container, Image2Contain } from './residence4.scss.js';
import IO from 'components/io';
import PageHeader from 'components/pageHeader';

const Residences4 = ({ image1, image2, quote, logo, link }) => {
  return (
    <IO rootMargin="-50px">
      {({ isVisible }) => (
        <Container isVisible={isVisible}>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <PageHeader>
              <>
                <h1>
                  {quote}
                  <br />
                  <img src={logo} alt="CBS Logo" />
                </h1>
                <Img
                  className="big-img"
                  fluid={image1 ? image1.childImageSharp.fluid : {}}
                  alt="The Getty Residences featured on CBS News"
                />
              </>
            </PageHeader>

            <Image2Contain isVisible={isVisible}>
              <Img
                className="image2"
                fluid={image2 ? image2.childImageSharp.fluid : {}}
              />
            </Image2Contain>
          </a>
        </Container>
      )}
    </IO>
  );
};

Residences4.propTypes = {
  image1: PropTypes.object.isRequired,
  image2: PropTypes.object.isRequired,
  quote: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default Residences4;
