import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Image2Contain = styled.div`
  position: absolute;
  overflow: auto;
  z-index: 10;
  right: 0;
  top: ${px2rem(200)};
  width: ${px2rem(600)};
  height: ${px2rem(983)};
  transition: opacity 2000ms, transform 2000ms;
  transform: translateY(${({ isVisible }) => (isVisible ? '0' : '-200px')});
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${MEDIA.DESKTOP`
      top: 0;
      left: 0;
      width: 50%;
      height: unset;
      position: relative;
      margin: 30px auto;
  `};
`;

export const Image3Contain = styled.div`
  /* position: absolute; */
  overflow: auto;
  z-index: 5;
  right: 0;
  max-width: ${px2rem(1920)};
  width: 100%;
  max-height: ${px2rem(1080)};
  transition: opacity 2000ms, transform 2000ms;
  transform: translateY(${({ isVisible }) => (isVisible ? '0' : '-20%')});
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  margin-top: ${px2rem(100)};

  ${MEDIA.DESKTOP`
      top: 0;
      left: 0;
      width: 90%;
      height: unset;
      position: relative;
      margin: auto;
  `};
`;

export const Caption = styled.div`
  position: relative;
  font-size: 12px;
  text-align: left;
  text-decoration: none;
  color: #000;
  margin-top: 5px;
  margin-left: 5px;
`;

export const Container = styled.div`
  position: relative;
  text-align: center;
  color: #fff;
  margin-top: 80px;
  margin-bottom: ${px2rem(80)};

  ${MEDIA.DESKTOP`
    margin-top: 50px;
  `};

  a {
    text-decoration: none;
  }

  .main-img {
    max-width: ${px2rem(1260)};
    max-height: ${px2rem(728)};
    transition: opacity 2000ms;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    overflow: visible;
  }

  .image2 {
    max-width: ${px2rem(702)};
    max-height: ${px2rem(983)};
  }
`;
