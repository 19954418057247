import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Image1Contain = styled.div`
  position: absolute;
  z-index: 10;
  right: 0;
  top: 0;
  width: ${px2rem(972)};
  height: ${px2rem(744)};

  transition: opacity 1500ms, transform 2000ms;
  transform: translateY(${({ isVisible }) => (isVisible ? '0' : '50px')});
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${MEDIA.DESKTOP`
      top: 0;
      left: 0;
      width: 70%;
      height: unset;
      position: relative;
      margin: auto;
      margin-top: 20px;
  `};
`;

export const Image2Contain = styled.div`
  position: absolute;
  overflow: auto;
  z-index: 20;
  right: ${px2rem(200)};
  top: ${px2rem(620)};

  width: ${px2rem(375)};
  height: ${px2rem(562)};

  transition: opacity 1500ms, transform 2000ms;
  transform: translateY(${({ isVisible }) => (isVisible ? '0' : '-50px')});
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${MEDIA.DESKTOP`
      top: 0;
      left: 0;
      width: 40%;
      height: unset;
      position: relative;
      margin: auto;
      margin-top: 20px;
  `};
`;

export const Caption = styled.div`
  position: relative;
  font-size: 12px;
  text-align: right;
  text-decoration: none;
  color: #000;
  margin-bottom: 15px;
  margin-right: 15px;
`;

export const Container = styled.div`
  position: relative;
  text-align: center;
  color: #fff;
  height: ${px2rem(1068)};

  a {
    text-decoration: none;
  }

  ${MEDIA.DESKTOP`
      height: unset;
  `};
`;
