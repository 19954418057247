import React from 'react';
import PropTypes from 'prop-types';
import { Container, Caption } from './crafted.scss.js';
import IO from 'components/io';

const Crafted = ({ hero, title, text, secondary }) => {
  return (
    <IO rootMargin="150px">
      {({ isVisible }) => (
        <Container isVisible={isVisible}>
          <img className="main-img" src={hero} alt={title} />
          <div className="bottom-row">
            <img className="aside-img" src={secondary} alt={title} />
            <Caption isVisible={isVisible}>
              <h1>{title}</h1>
              <p>{text}</p>
            </Caption>
          </div>
        </Container>
      )}
    </IO>
  );
};

Crafted.propTypes = {
  title: PropTypes.string.isRequired,
  hero: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  secondary: PropTypes.string.isRequired,
};

export default Crafted;
