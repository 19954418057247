import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Image1Contain = styled.div`
  position: absolute;
  z-index: 5;
  left: 0;
  top: ${px2rem(0)};
  max-width: ${px2rem(1079)};
  height: ${px2rem(723)};
  width: 100%;
  transition: opacity 2000ms, transform 2000ms;
  transform: translateX(${({ isVisible }) => (isVisible ? '0' : '50px')});
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${MEDIA.DESKTOP`
    top: 0;
    left: 0;
    width: 100%;
    height: unset;
    position: relative;
    margin: 30px auto;
  `};
`;
export const Image2Contain = styled.div`
  position: absolute;
  z-index: 10;
  right: ${px2rem(44)};
  top: ${px2rem(146)};
  max-width: ${px2rem(645)};
  height: ${px2rem(430)};
  width: 45%;

  transition: opacity 2000ms, transform 2000ms;
  transform: translateX(${({ isVisible }) => (isVisible ? '0' : '-50px')});
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${MEDIA.DESKTOP`
    top: 0;
    left: 0;
    width: 50%;
    height: unset;
    position: relative;
    margin: 30px auto;
  `};
`;

export const Caption = styled.div`
  position: relative;
  font-size: 12px;
  text-align: right;
  text-decoration: none;
  color: #000;
  margin-bottom: 5px;
  margin-right: 5px;
`;

export const Container = styled.div`
  position: relative;
  text-align: center;
  color: #fff;
  height: ${px2rem(723)};
  overflow: hidden;

  a {
    text-decoration: none;
  }

  ${MEDIA.DESKTOP`
    height: unset;
  `};
`;
