import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './footerImg.scss.js';
import IO from 'components/io';

const FooterImg = ({ src, alt }) => {
  return (
    <IO rootMargin="-50px">
      {({ isVisible }) => (
        <Container isVisible={isVisible}>
          <img
            style={{
              marginBottom: 0,
              display: 'block',
              width: '100%',
            }}
            src={src}
            alt={alt}
          />
        </Container>
      )}
    </IO>
  );
};

FooterImg.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default FooterImg;
