import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './button.scss.js';
import IO from 'components/io';

const Button = ({ text, url, external }) => {
  return (
    <IO rootMargin="50px">
      {({ isVisible }) => (
        <Container isVisible={isVisible} className="getty-button">
          {external && (
            <a href={url} target="_blank" rel="noopener noreferrer">
              {text}
            </a>
          )}
          {!external && <a href={url}>{text}</a>}
        </Container>
      )}
    </IO>
  );
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  external: PropTypes.bool,
};

Button.defaultProps = {
  external: false,
};

export default Button;
