import React from 'react';
import PropTypes from 'prop-types';
import { Container, Caption, BottomRow } from './penthouse.css';
import IO from 'components/io';

const Penthouse = ({ title, text, quote, sketch, photo, logo, link }) => {
  return (
    <IO rootMargin="150px">
      {({ isVisible }) => (
        <Container isVisible={isVisible}>
          <h1>{title}</h1>

          <div className="top-header">
            <div
              className="hero-img"
              style={{ backgroundImage: `url(${photo})` }}
            />

            <Caption isVisible={isVisible}>
              <a href={link} target="_blank" rel="noopener noreferrer">
                <p>{quote}</p>
                <img className="logo-img" src={logo} alt={title} />
              </a>
            </Caption>
          </div>

          <BottomRow>
            <p className="below-text">{text}</p>
            <img className="aside-img" src={sketch} alt={title} />
          </BottomRow>
        </Container>
      )}
    </IO>
  );
};

Penthouse.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  sketch: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default Penthouse;
