import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import {
  Container,
  Image1Contain,
  Image2Contain,
  Image3Contain,
} from './residence5.scss.js';
import IO from 'components/io';

const Residence5 = ({ quote, logo, image1, image2, image3, link }) => {
  return (
    <IO rootMargin="-50px">
      {({ isVisible }) => (
        <Container isVisible={isVisible}>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <div className="caption">
              {quote}
              <img src={logo} alt="AD Logo" />
            </div>

            <Image1Contain isVisible={isVisible}>
              <Img
                className="main-img"
                fluid={image1 ? image1.childImageSharp.fluid : {}}
              />
            </Image1Contain>

            <Image2Contain isVisible={isVisible}>
              <Img
                className="image2"
                fluid={image2 ? image2.childImageSharp.fluid : {}}
              />
            </Image2Contain>

            <Image3Contain isVisible={isVisible}>
              <Img
                className="image3"
                fluid={image3 ? image3.childImageSharp.fluid : {}}
              />
            </Image3Contain>
          </a>
        </Container>
      )}
    </IO>
  );
};

Residence5.propTypes = {
  quote: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  image1: PropTypes.object.isRequired,
  image2: PropTypes.object.isRequired,
  image3: PropTypes.object.isRequired,
  link: PropTypes.string.isRequired,
};

export default Residence5;
