import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const LogoAction = styled.div`
  display: flex;
  height: 100vh;

  animation-name: logoMove;
  animation-duration: 0.8s;
  animation-delay: 1.2s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;

  justify-content: center;
  flex-direction: column;

  @keyframes logoMove {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 1);
  animation-fill-mode: forwards;
  animation-name: bgFade;
  animation-duration: 800ms;
  animation-delay: 2.2s;
  animation-timing-function: ease-out;

  @keyframes bgFade {
    from {
      height: 100vh;
    }
    to {
      height: 0;
    }
  }

  ${MEDIA.DESKTOP`
    display: none;
  `};

  #gettyLogo {
    transform: translateY(${px2rem(52)});
  }

  p {
    margin: 0;
    padding: 0 ${px2rem(100)};
    margin-bottom: ${px2rem(120)};
    width: 200px;
    height: 11px;
    font-family: 'EngraversMT';
    font-size: 14px;
    line-height: 1.88;
    text-align: center;
    color: #000000;
    opacity: 0;
    animation-fill-mode: forwards;
    animation-name: tagFade;
    animation-duration: 0.3s;
    animation-delay: 0.1s;
    animation-timing-function: ease-out;

    @keyframes tagFade {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    &::after {
      content: '';
      display: block;
      width: 2px;
      margin: auto;
      margin-top: 15px;
      height: ${px2rem(120)};
      background-color: black;
      transform: translateX(-2px);

      animation-fill-mode: forwards;
      animation-name: tagFadeOut;
      animation-duration: 0.3s;
      animation-delay: 1.9s;
      animation-timing-function: ease-in;

      @keyframes tagFadeOut {
        from {
          opacity: 1;
        }
        to {
          opacity: 0;
        }
      }
    }
  }
`;
