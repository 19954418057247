import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from 'components/layout';
import Head from 'components/head';
import TextBlock from 'components/textBlock';
import Residence1 from 'components/residence1';
import QuoteLogo from 'components/quotelogo';
import Residence2 from 'components/residence2';
import Residence3 from 'components/residence3';
import Residence4 from 'components/residence4';
import Residence5 from 'components/residence5';
import Residence6 from 'components/residence6';
import Residence7 from 'components/residence7';
import PageHeader from 'components/pageHeader';
import { Navline } from 'styles/utility.scss.js';

const Works = ({ data }) => (
  <Layout>
    <Head pageTitle={data.worksJson.title} />
    <Navline />
    <PageHeader>
      <>
        <h1>
          <a
            href={data.worksJson.imageheader.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.worksJson.imageheader.text}
            <br />
            <img
              src={data.worksJson.imageheader.logo.publicURL}
              alt="AD Logo"
            />
          </a>
        </h1>
        <Img
          className="big-img"
          fluid={
            data.worksJson.imageheader.image
              ? data.worksJson.imageheader.image.childImageSharp.fluid
              : {}
          }
          alt={data.worksJson.title}
        />
      </>
    </PageHeader>

    <TextBlock text={data.worksJson.textblock1.text} showTopLine />

    <Residence1
      image1={data.worksJson.residence1.image1}
      image2={data.worksJson.residence1.image2}
      image3={data.worksJson.residence1.image3}
      caption={data.worksJson.residence1.caption}
      link={data.worksJson.residence1.link}
    />

    <QuoteLogo
      quote={data.worksJson.quote1.text}
      logo={data.worksJson.quote1.logo.publicURL}
      link={data.worksJson.quote1.link}
      logoSize={400}
    />

    <Residence2
      image1={data.worksJson.residence2.image1}
      image2={data.worksJson.residence2.image2}
      caption={data.worksJson.residence2.caption}
      link={data.worksJson.residence2.link}
      quote={data.worksJson.residence2.quote}
      logo={data.worksJson.residence2.logo.publicURL}
    />

    <Residence3
      image1={data.worksJson.residence3.image1}
      image2={data.worksJson.residence3.image2}
      caption={data.worksJson.residence3.caption}
      link={data.worksJson.residence3.link}
    />

    <Residence4
      image1={data.worksJson.residence4.image1}
      image2={data.worksJson.residence4.image2}
      quote={data.worksJson.residence4.quote}
      logo={data.worksJson.residence4.logo.publicURL}
      link={data.worksJson.residence4.link}
    />

    <Residence5
      image1={data.worksJson.residence5.image1}
      image2={data.worksJson.residence5.image2}
      image3={data.worksJson.residence5.image3}
      quote={data.worksJson.residence5.quote}
      logo={data.worksJson.residence5.logo.publicURL}
      link={data.worksJson.residence5.link}
    />

    <TextBlock text={data.worksJson.textblock2.text} />

    <Residence6
      image1={data.worksJson.residence6.image1}
      image2={data.worksJson.residence6.image2}
      caption={data.worksJson.residence6.caption}
      link={data.worksJson.residence6.link}
    />

    <Residence7
      image1={data.worksJson.residence7.image1}
      image2={data.worksJson.residence7.image2}
      image3={data.worksJson.residence7.image3}
      caption={data.worksJson.residence7.caption}
      link={data.worksJson.residence7.link}
    />

    <PageHeader>
      <>
        <h1>
          <a
            href={data.worksJson.imagefooter.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.worksJson.imagefooter.text}
            <br />
            <img
              src={data.worksJson.imagefooter.logo.publicURL}
              alt="Vogue Logo"
            />
          </a>
        </h1>
        <Img
          className="big-img"
          fluid={
            data.worksJson.imagefooter.image1
              ? data.worksJson.imagefooter.image1.childImageSharp.fluid
              : {}
          }
          alt={data.worksJson.title}
        />
      </>
    </PageHeader>
  </Layout>
);

Works.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Works;

export const query = graphql`
  query WorksQuery {
    worksJson {
      title
      imageheader {
        text
        link
        logo {
          publicURL
        }
        image {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      textblock1 {
        text
      }
      residence1 {
        image1 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image3 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        caption
        link
      }
      quote1 {
        text
        link
        logo {
          publicURL
        }
      }
      residence2 {
        image1 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        logo {
          publicURL
        }
        quote
        caption
        link
      }
      residence3 {
        image1 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        caption
        link
      }
      residence4 {
        quote
        link
        logo {
          publicURL
        }
        image1 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      textblock2 {
        text
      }
      residence5 {
        quote
        link
        logo {
          publicURL
        }
        image1 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image3 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      residence6 {
        caption
        link
        image1 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      residence7 {
        caption
        link
        image1 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image3 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      imagefooter {
        text
        link
        logo {
          publicURL
        }
        image1 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
