import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { graphql } from 'gatsby';
import renderHTML from 'react-render-html';
import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import { Navline } from 'styles/utility.scss.js';
import MEDIA from 'helpers/mediaTemplates';

export const Policy = styled.div`
  max-width: ${px2rem(960)};
  text-align: center;
  margin: auto;
  padding: 60px 20px;
  line-height: 1.22;
  margin-top: 100px;
  h1 {
    font-size: ${px2rem(32)};
    margin: 30px 0;
  }
  h2 {
    font-size: ${px2rem(21)};
    margin: 30px 0;
  }
  p {
    font-size: ${px2rem(18)};
    margin: 10px 0;
    text-align: justify;
    text-align-last: center;
  }
  a {
    color: black;
  }

  ${MEDIA.DESKTOP`
    h1 { font-size: 20px; }
    h2 { font-size: 18px; }
    p { font-size: 13px; }
  `};
`;

const Privacy = ({ data }) => {
  return (
    <Layout>
      <Navline />
      <Policy>
        {renderHTML(data.privacypolicyJson.content.childMarkdownRemark.html)}
      </Policy>
    </Layout>
  );
};

Privacy.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Privacy;

export const query = graphql`
  query PrivacypageQuery {
    privacypolicyJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
    }
  }
`;
