import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Caption = styled.div`
  position: absolute;
  z-index: 200;
  left: 0;
  right: 0;
  top: ${px2rem(338)};
  width: ${px2rem(1107)};
  margin: auto;
  text-align: left;
  color: #ffffff;
  transition-delay: 200ms;
  transition: opacity 2400ms;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  a {
    color: #fff;
    text-decoration: none;
  }

  ${MEDIA.DESKTOP`
    top: 140px;
    width: auto;
  `};

  p {
    width: ${px2rem(1107)};
    font-size: ${px2rem(72)};
    line-height: 1.39;

    ${MEDIA.DESKTOP`
      width: auto;
      padding: 0 20px;
    `};

    ${MEDIA.TABLET`
      font-size: ${px2rem(60)};
      line-height: 1.3;
    `};
  }

  .logo-img {
    margin-top: ${px2rem(30)};
    width: ${px2rem(223)};
    ${MEDIA.DESKTOP`
      padding: 0 20px;
    `};
    ${MEDIA.TABLET`
      margin-top: ${px2rem(50)};
      width: ${px2rem(170)};
    `};
  }
`;

export const BottomRow = styled.div`
  ${MEDIA.DESKTOP`
    display: flex;
    align-items: center;
  `};

  ${MEDIA.TABLET`
    flex-direction: column;
  `};
`;

export const Container = styled.div`
  position: relative;

  max-width: ${px2rem(1354)};

  margin: auto;
  margin-top: ${px2rem(470)};
  margin-bottom: 60px;

  ${MEDIA.DESKTOP`
      max-height: unset;
      justify-content: flex-start;
      margin-top: 40px;
  `};

  h1 {
    font-size: ${px2rem(100)};
    margin-bottom: ${px2rem(44)};
    line-height: 1;
    z-index: 100;
    text-align: center;
    padding: 0 20px;
    transition: opacity 1600ms, transform 2000ms;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transform: translateY(${({ isVisible }) => (isVisible ? '0%' : '-100%')});

    ${MEDIA.TABLET`
      font-size: ${px2rem(80)};
    `};
  }

  .hero-img {
    display: block;
    position: absolute;
    margin: auto;
    height: ${px2rem(734)};
    max-width: ${px2rem(1354)};
    width: 100%;
    z-index: 200;
    object-fit: cover;
    background-attachment: fixed;
    background-size: 100% auto;
    background-position: top center;

    ${MEDIA.DESKTOP`
      position: relative;
      background-attachment: scroll;
      background-size: 100% auto;
      background-position: top center;
    `};
  }

  .top-header {
    height: ${px2rem(734)};
    ${MEDIA.DESKTOP`
      height: unset;
    `};
  }

  .below-text {
    margin-top: ${px2rem(60)};
    width: ${px2rem(725)};
    height: ${px2rem(265)};
    font-size: ${px2rem(25)};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.04;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    padding: 0;
    transition-delay: 1000ms;
    transition: opacity 2400ms, transform 3000ms;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transform: translateY(${({ isVisible }) => (isVisible ? '0%' : '50%')});

    ${MEDIA.LAPTOP`
      padding-left: 40px;
    `};

    ${MEDIA.DESKTOP`
      width: auto;
      padding: 0 20px;
      margin-top: 0;
    `};

    ${MEDIA.TABLET`
      margin-top: 30px;
      margin-bottom: 20px;
      font-size: ${px2rem(27)};
      line-height: 1.65;
    `};
  }

  .aside-img {
    transition: opacity 2400ms;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

    position: absolute;
    left: ${px2rem(-184)};
    top: ${px2rem(-300)};
    z-index: 1;
    flex-shrink: 1;

    text-align: right;
    width: ${px2rem(471)};
    height: ${px2rem(729)};
    object-fit: contain;

    ${MEDIA.DESKTOP`
      top: 0;
      left: 0;
      opacity: 1;
      flex-shrink: 1;
      margin-top: 30px;
      width: auto;
      position: relative;
    `};
  }
`;
