import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  font-size: ${px2rem(264)};
  max-width: ${px2rem(1440)};
  overflow: hidden;
  margin: auto;
  text-transform: uppercase;
  font-weight: normal;
  line-height: 0.7;
  color: #000000;
  margin-bottom: ${px2rem(80)};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${MEDIA.TABLET`
    font-size: ${px2rem(170)};
  `};
  ${MEDIA.PHONE`
    font-size: ${px2rem(110)};
  `};
`;

export const Line1Contain = styled.div`
  text-align: left;
  display: block;
  transition: opacity 2000ms, transform 2000ms;
  transform: translateX(${({ isVisible }) => (isVisible ? '0' : '200px')});
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`;

export const Line2Contain = styled.div`
  display: block;
  text-align: right;
  transition: opacity 2000ms, transform 2000ms;
  transform: translateX(${({ isVisible }) => (isVisible ? '0' : '-200px')});
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
`;
