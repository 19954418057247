import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Image2Contain = styled.div`
  position: absolute;
  overflow: auto;
  z-index: 10;
  right: ${px2rem(44)};
  top: ${px2rem(800)};
  width: ${px2rem(590)};
  height: ${px2rem(394)};
  transition: opacity 2000ms, transform 2000ms;
  transform: translateY(${({ isVisible }) => (isVisible ? '0' : '100px')});
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${MEDIA.DESKTOP`
      top: 0;
      left: 0;
      width: 50%;
      height: unset;
      position: relative;
      margin: 30px auto;
  `};
`;

export const Container = styled.div`
  position: relative;
  text-align: center;
  color: #fff;
  height: ${px2rem(1416)};
  margin-top: 70px;
  overflow: hidden;

  a {
    text-decoration: none;
  }

  ${MEDIA.DESKTOP`
      height: unset;
  `};
`;
