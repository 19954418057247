import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './bigquote.scss.js';
import IO from 'components/io';

const Bigquote = ({ text, logo, link }) => {
  return (
    <IO rootMargin="100px">
      {({ isVisible }) => (
        <Container isVisible={isVisible}>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <p>{text}</p>
            <img className="logo-img" src={logo} alt={text} />
          </a>
        </Container>
      )}
    </IO>
  );
};

Bigquote.propTypes = {
  text: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default Bigquote;
