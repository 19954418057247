import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import {
  Container,
  Image1Contain,
  Image2Contain,
  Caption,
} from './residence6.scss.js';
import IO from 'components/io';

const Residence6 = ({ image1, image2, caption, link }) => {
  return (
    <IO rootMargin="-50px">
      {({ isVisible }) => (
        <Container isVisible={isVisible}>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <Image1Contain isVisible={isVisible}>
              <Caption>{caption}</Caption>
              <Img fluid={image1 ? image1.childImageSharp.fluid : {}} />
            </Image1Contain>

            <Image2Contain isVisible={isVisible}>
              <Img
                className="image2"
                fluid={image2 ? image2.childImageSharp.fluid : {}}
              />
            </Image2Contain>
          </a>
        </Container>
      )}
    </IO>
  );
};

Residence6.propTypes = {
  image1: PropTypes.object.isRequired,
  image2: PropTypes.object.isRequired,
  caption: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default Residence6;
