import React from 'react';
import Layout from 'components/layout';
import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import { Navline } from 'styles/utility.scss.js';
import MEDIA from 'helpers/mediaTemplates';

export const ThanksWrap = styled.div`
  max-width: ${px2rem(960)};
  text-align: center;
  margin: auto;
  padding: 60px 20px;
  line-height: 1.22;
  display: flex;
  margin-top: 120px;
  justify-content: center;
  min-height: calc(100vh - 600px);
  flex-direction: column;

  h1 {
    font-size: ${px2rem(32)};
    margin: 30px 0;
  }

  h2 {
    font-size: ${px2rem(21)};
    margin: 30px 0;
  }

  p {
    font-size: ${px2rem(18)};
    margin: 10px 0;
    text-align: justify;
    text-align-last: center;
  }

  a {
    color: black;
  }

  ${MEDIA.DESKTOP`
    h1 { font-size: 20px; }
    h2 { font-size: 18px; }
    p { font-size: 13px; }
  `};
`;

const Thanks = () => {
  return (
    <Layout>
      <Navline />
      <ThanksWrap>
        <h1>Thank you for registering with The Getty Residences.</h1>
        <h2>A sales associate will contact you soon.</h2>
      </ThanksWrap>
    </Layout>
  );
};

export default Thanks;
