import React from 'react';
import PropTypes from 'prop-types';
import IO from 'components/io';
import { Container } from './pressCallout.css';

const PressCallout = ({ children, link }) => (
  <IO rootMargin="-50px">
    {({ isVisible }) => (
      <Container isVisible={isVisible}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      </Container>
    )}
  </IO>
);

PressCallout.propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
};

export default PressCallout;
