import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const MobileMenuContain = styled.div`
  position: absolute;
  top: 125px;
  left: 0;
  z-index: 5000;
  height: calc(100vh - 128px);
  width: 100%;
  background-color: white;

  nav {
    display: block !important;
    justify-content: column;

    ul {
      position: relative;
      flex-direction: column;
      width: 100%;
      right: 0;
    }

    li {
      display: block;
      width: 100%;
      text-align: center;
      margin-left: 0;
      font-size: 24px;
      margin: 30px 0;
    }

    li + li {
      margin-left: 0;
    }

    ${MEDIA.TABLET`
        display: block;
    `};
  }
`;

export const Container = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  background-color: white;

  opacity: 0;
  animation-fill-mode: forwards;
  animation-name: navFade;
  animation-timing-function: ease-in;

  animation-duration: 300ms;
  animation-delay: 300ms;

  &.page-home {
    animation-duration: 1.2s;
    animation-delay: 3s;
  }

  @keyframes navFade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  ${MEDIA.TABLET`
    opacity: 1;
    padding: 14px 0;
  `}

  ${MEDIA.DESKTOP`
      justify-content: center;
      flex-direction: column;
      align-items: center;
  `};

  .mobile-toggle {
    border: 0px;
    background: none;
    /* margin-right: 20px; */
    display: none;

    ${MEDIA.TABLET`
      margin-top: 11px;
      margin-right: 0;
      display: block;
    `};

    svg {
      g {
        fill: black;
      }
    }
  }

  nav {
    ${MEDIA.TABLET`
        display: none;
    `};
  }

  a {
    color: black;
    text-decoration: none;
    transition: color 0.2s ease;

    &:hover {
      transition: color 0.2s ease;
      color: #757575;
    }
  }
`;
