import React from 'react';
import PropTypes from 'prop-types';
import IO from 'components/io';
import { Container, Line1Contain, Line2Contain } from './bigText.scss.js';

const BigText = ({ line1, line2 }) => {
  return (
    <IO rootMargin="100px">
      {({ isVisible }) => (
        <Container isVisible={isVisible}>
          <Line1Contain isVisible={isVisible}>{line1}</Line1Contain>
          <Line2Contain isVisible={isVisible}>{line2}</Line2Contain>
        </Container>
      )}
    </IO>
  );
};

BigText.propTypes = {
  line1: PropTypes.string.isRequired,
  line2: PropTypes.string.isRequired,
};

export default BigText;
