import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, MobileMenuContain } from './header.css';
import Nav from 'components/header/nav';
import Logo from 'components/logo';
// import { Link } from 'gatsby';

import { Location } from '@reach/router';

const MobileMenu = () => (
  <MobileMenuContain>
    <Nav />
  </MobileMenuContain>
);

const Hamburger = ({ toggleFunction }) => (
  <button type="button" onClick={toggleFunction} className="mobile-toggle">
    <svg width="20px" height="16px" viewBox="0 0 20 16">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="menu" fill="#B0B0B0">
          <rect id="Rectangle-Copy-8" x="0" y="0" width="20" height="2" />
          <rect id="Rectangle-Copy-9" x="0" y="7" width="20" height="2" />
          <rect id="Rectangle-Copy-10" x="0" y="14" width="20" height="2" />
        </g>
      </g>
    </svg>
  </button>
);

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Location>
      {({ location }) => {
        const compedClass =
          location.pathname === '/' ? 'page-home' : 'page-other';

        return (
          <Container className={compedClass}>
            <a href="/">
              <Logo />
            </a>
            <Nav />
            <Hamburger toggleFunction={toggle} />

            {isOpen && <MobileMenu />}
          </Container>
        );
      }}
    </Location>
  );
};

Hamburger.propTypes = {
  toggleFunction: PropTypes.func.isRequired,
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Header;
