import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import Neighborhood from 'components/neighborhood';
import Intro from 'components/residencesIntro';
import Button from 'components/button';
import BigText from 'components/bigText';
import Apt5 from 'components/apt5';
import Apt6 from 'components/apt6';
import Apt7 from 'components/apt7';
import Apt8 from 'components/apt8';
import AptPH from 'components/aptPH';
import FooterImg from 'components/footerImg';
import ResidencesPress1 from 'components/residencesPress1';
import ResidencesPress2 from 'components/residencesPress2';
import Img from 'gatsby-image';
import PageHeader from 'components/pageHeader';
import { Navline } from 'styles/utility.scss.js';

const Residences = ({ data }) => (
  <Layout>
    <Head pageTitle={data.residencesJson.title} />
    <Navline />
    <PageHeader>
      <>
        <h1>
          {data.residencesJson.imageheader.text}
          <br />
          <img
            src={data.residencesJson.imageheader.logo.publicURL}
            alt="Watch Logo"
            style={{ width: '200px' }}
          />
        </h1>
        <Img
          className="big-img"
          fluid={
            data.residencesJson.imageheader.image
              ? data.residencesJson.imageheader.image.childImageSharp.fluid
              : {}
          }
          alt={data.residencesJson.title}
        />
      </>
    </PageHeader>

    <BigText
      line1={data.residencesJson.bigtext.line1}
      line2={data.residencesJson.bigtext.line2}
    />

    <Intro
      title={data.residencesJson.intro.title}
      image1={data.residencesJson.intro.image1}
      image2={data.residencesJson.intro.image2}
    />

    <Apt5
      image1={data.residencesJson.apt5.image1}
      image2={data.residencesJson.apt5.image2}
      text={data.residencesJson.apt5.text}
      number={data.residencesJson.apt5.number}
      label={data.residencesJson.apt5.label}
      floorplan={data.residencesJson.apt5.floorplan.publicURL}
    />

    <ResidencesPress1
      quote={data.residencesJson.press1.quote}
      credit={data.residencesJson.press1.credit}
      logo={data.residencesJson.press1.logo}
      image={data.residencesJson.press1.image}
      link={data.residencesJson.press1.link}
    />

    <Apt6
      image1={data.residencesJson.apt6.image1}
      image2={data.residencesJson.apt6.image2}
      text={data.residencesJson.apt6.text}
      number={data.residencesJson.apt6.number}
      label={data.residencesJson.apt6.label}
      floorplan={data.residencesJson.apt6.floorplan.publicURL}
    />

    <ResidencesPress2
      quote={data.residencesJson.press2.quote}
      logo={data.residencesJson.press2.logo}
      link={data.residencesJson.press2.link}
      shortTop={true}
    />

    <Apt7
      image1={data.residencesJson.apt7.image1}
      image2={data.residencesJson.apt7.image2}
      image3={data.residencesJson.apt7.image3}
      text={data.residencesJson.apt7.text}
      number={data.residencesJson.apt7.number}
      label={data.residencesJson.apt7.label}
      floorplan={data.residencesJson.apt7.floorplan.publicURL}
    />

    <ResidencesPress2
      quote={data.residencesJson.press3.quote}
      logo={data.residencesJson.press3.logo}
      link={data.residencesJson.press3.link}
    />

    <Apt8
      image1={data.residencesJson.apt8.image1}
      image2={data.residencesJson.apt8.image2}
      text={data.residencesJson.apt8.text}
      number={data.residencesJson.apt8.number}
      label={data.residencesJson.apt8.label}
      floorplan={data.residencesJson.apt8.floorplan.publicURL}
    />

    <ResidencesPress2
      quote={data.residencesJson.press4.quote}
      logo={data.residencesJson.press4.logo}
      link={data.residencesJson.press4.link}
    />

    <AptPH
      image1={data.residencesJson.aptPH.image1}
      image2={data.residencesJson.aptPH.image2}
      text={data.residencesJson.aptPH.text}
      number={data.residencesJson.aptPH.number}
      label={data.residencesJson.aptPH.label}
    />

    <ResidencesPress2
      quote={data.residencesJson.press5.quote}
      logo={data.residencesJson.press5.logo}
      link={data.residencesJson.press5.link}
    />
    <br />
    <br />
    <br />

    <Neighborhood
      title={data.residencesJson.uws.title}
      map={data.residencesJson.uws.map}
    />

    <Button text={'Peter Marino'} url="/works" />

    <FooterImg
      src={data.residencesJson.footer.image.publicURL}
      alt="The Getty Residences by Peter Marino"
    />
  </Layout>
);

Residences.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Residences;

export const query = graphql`
  query ResidencesQuery {
    residencesJson {
      title
      imageheader {
        text
        logo {
          publicURL
        }
        image {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      bigtext {
        line1
        line2
      }
      intro {
        title
        image1 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      apt5 {
        image1 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        text
        number
        label
        floorplan {
          publicURL
        }
      }
      press1 {
        quote
        credit
        link
        logo {
          publicURL
        }
        image {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      apt6 {
        image1 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        text
        number
        label
        floorplan {
          publicURL
        }
      }
      press2 {
        quote
        link
        logo {
          publicURL
        }
      }
      apt7 {
        image1 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image3 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        text
        number
        label
        floorplan {
          publicURL
        }
      }
      press3 {
        quote
        link
        logo {
          publicURL
        }
      }
      apt8 {
        image1 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        text
        number
        label
        floorplan {
          publicURL
        }
      }
      press4 {
        quote
        link
        logo {
          publicURL
        }
      }
      aptPH {
        image1 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image2 {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        text
        number
        label
      }
      press5 {
        quote
        link
        logo {
          publicURL
        }
      }
      uws {
        title
        map {
          publicURL
        }
      }
      footer {
        image {
          publicURL
        }
      }
    }
  }
`;
