import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Image1 = styled.div`
  position: absolute;
  width: ${px2rem(725)};
  height: ${px2rem(640)};

  transition: opacity 2400ms, transform 3000ms;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: translateY(${({ isVisible }) => (isVisible ? '35%' : '10%')});

  z-index: 100;

  ${MEDIA.WIDE`
    transform: translateY(${({ isVisible }) => (isVisible ? '15%' : '0%')});
    width: ${px2rem(670)};
  `};

  ${MEDIA.DESKTOP`

    transform: translateY(${({ isVisible }) => (isVisible ? '-50%' : '2%')});
    transition: opacity 1800ms, transform 2000ms;

    top: 0;
    right: 0;
    height: unset;
    margin: auto;
    margin-bottom: 40px;
    position: absolute;
    width: 100%;
    max-width: ${px2rem(725)};
    max-height: ${px2rem(640)};
  `};

  ${MEDIA.TABLET`
    width: 52%;
    margin-right: 0;
    margin-left: auto;
  `};
`;

export const Image2 = styled.div`
  position: absolute;
  z-index: 0;
  right: 0;
  width: ${px2rem(831)};
  height: ${px2rem(900)};
  transition: opacity 2400ms, transform 3000ms;
  opacity: ${({ isVisible }) => (isVisible ? 0.9 : 0)};
  transform: translateY(${({ isVisible }) => (isVisible ? '-30%' : '2%')});

  ${MEDIA.WIDE`
    width: 40%;
  `};

  ${MEDIA.DESKTOP`
    transform: translateY(${({ isVisible }) => (isVisible ? '-57px' : '20%')});
    position: relative;
    width: 100%;
    margin: auto;
    left: 0;
    right: 0;
    height: unset;
    margin-bottom: 0;
    max-width: ${px2rem(831)};
  `};
`;

export const Image3 = styled.div`
  width: ${px2rem(958)};
  height: ${px2rem(705)};
  margin: auto;
  transition-delay: 600ms;
  transition: opacity 2400ms, transform 3000ms;
  transform: translateY(${({ isVisible }) => (isVisible ? '0%' : '-100%')});

  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${MEDIA.DESKTOP`
    transform: translateY(${({ isVisible }) => (isVisible ? '0%' : '-40%')});
    transition: opacity 1800ms, transform 2000ms;
    position: relative;
    width: 90%;
    margin-left: 0;
    height: unset;
    max-width: ${px2rem(1064)};
    max-height: ${px2rem(784)};
  `};
`;

export const Number = styled.div`
  width: ${px2rem(577)};
  height: ${px2rem(273)};
  font-size: ${px2rem(264)};
  line-height: 0.8;
  letter-spacing: normal;
  text-align: center;
  margin: auto;
  z-index: 100;
`;

export const Describe = styled.div`
  width: ${px2rem(593)};
  font-size: ${px2rem(25)};
  line-height: 2.04;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin: auto;
  margin-bottom: 30px;
  z-index: 200;
`;

export const Container = styled.div`
  position: relative;
  text-align: center;
  color: black;
  margin-top: 80px;
  height: ${px2rem(1910)};

  ${MEDIA.DESKTOP`
    height: unset;
    margin-top: 200px;

  `};

  .top-contain {
    overflow: auto;
    height: ${px2rem(1200)};

    ${MEDIA.DESKTOP`
      top: 0;
      overflow: unset;
      height: unset;
    `};
  }
`;

export const DescribeContain = styled.div`
  width: ${px2rem(593)};
  height: ${px2rem(941)};
  position: absolute;
  z-index: 100;
  margin: auto;
  top: ${px2rem(480)};
  left: 0;
  right: 0;

  .getty-button {
    margin: 0;
  }

  ${MEDIA.DESKTOP`
    h1 {
      font-size: 14px;
      margin-bottom: 2px;
    }
    position: relative;
    margin: auto;
    margin-bottom: 30px;
    margin-top: 40px;
    top: 0;
    height: unset;
  `};
`;
