import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import { graphql } from 'gatsby';

import Intro from 'components/intro';
import VideoHeader from 'components/videoHeader';
import TextBlock from 'components/textBlock';
import ImageHeroTitle from 'components/imageherotitle';
import PressCallout from 'components/pressCallout';
import ImageTitle from 'components/imageTitle';
import Quote from 'components/quote';
import Residences from 'components/residences';
import Crafted from 'components/crafted';
import Penthouse from 'components/penthouse';
import Bigquote from 'components/bigquote';
import Neighborhood from 'components/neighborhood';
import Button from 'components/button';

import FooterImg from 'components/footerImg';

import { Navline } from 'styles/utility.scss';
const Index = ({ data }) => {
  return (
    <Layout>
      <Intro />

      <Navline />

      <VideoHeader>
        <>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            src="banner_4.mp4"
            preload="auto"
            muted
            loop
            playsInline
            autoPlay
          ></video>
          <h1>{data.homeJson.videoheader.text}</h1>
        </>
      </VideoHeader>

      <TextBlock text={data.homeJson.textblock.text} showTopLine />

      <ImageHeroTitle
        title={data.homeJson.hero1.title}
        image={data.homeJson.hero1.image}
        text={data.homeJson.hero1.text}
        aside={data.homeJson.hero1.aside}
        link={data.homeJson.hero1.link}
      />

      <PressCallout link={data.homeJson.article1.link}>
        <h1>{data.homeJson.article1.headline}</h1>
        <img
          src={data.homeJson.article1.logo.publicURL}
          alt={data.homeJson.article1.headline}
        />
      </PressCallout>

      <ImageTitle
        hero={data.homeJson.hero2.hero}
        title={data.homeJson.hero2.title}
        link={data.homeJson.hero2.link}
        text={data.homeJson.hero2.text}
      />

      <Quote
        title={data.homeJson.quote.title}
        quoted={data.homeJson.quote.quoted}
        logo={data.homeJson.quote.logo}
        background={data.homeJson.quote.background}
        link={data.homeJson.quote.link}
      ></Quote>

      <Residences
        title={data.homeJson.residences.title}
        cta={data.homeJson.residences.cta}
        floorplan={data.homeJson.residences.floorplan}
      ></Residences>

      <Crafted
        title={data.homeJson.crafted.title}
        text={data.homeJson.crafted.text}
        hero={data.homeJson.crafted.hero.publicURL}
        secondary={data.homeJson.crafted.secondary.publicURL}
      ></Crafted>

      <Penthouse
        title={data.homeJson.penthouse.title}
        quote={data.homeJson.penthouse.quote}
        text={data.homeJson.penthouse.text}
        sketch={data.homeJson.penthouse.sketch.publicURL}
        photo={data.homeJson.penthouse.photo.publicURL}
        logo={data.homeJson.penthouse.logo.publicURL}
        link={data.homeJson.penthouse.link}
      ></Penthouse>

      <Bigquote
        text={data.homeJson.bigquote.text}
        link={data.homeJson.bigquote.link}
        logo={data.homeJson.bigquote.logo.publicURL}
      />

      <Neighborhood
        title={data.homeJson.uws.title}
        map={data.homeJson.uws.map}
      />

      <Button text={data.homeJson.foot.title} url="/residences" />

      <FooterImg
        src={data.homeJson.foot.hero.publicURL}
        alt={data.homeJson.foot.title}
      />
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;

export const query = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      textblock {
        text
      }
      hero1 {
        title
        link
        text {
          childMarkdownRemark {
            html
            rawMarkdownBody
          }
        }
        aside {
          childImageSharp {
            fluid(quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image {
          childImageSharp {
            fluid(quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      videoheader {
        text
      }
      article1 {
        headline
        publication
        link
        logo {
          publicURL
        }
      }
      hero2 {
        title
        link
        text
        hero {
          childImageSharp {
            fluid(quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      quote {
        title
        quoted
        link
        logo {
          publicURL
        }
        background {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      residences {
        title
        cta
        floorplan {
          childImageSharp {
            fluid(quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      crafted {
        title
        text
        hero {
          publicURL
        }
        secondary {
          publicURL
        }
      }
      penthouse {
        title
        quote
        text
        link
        sketch {
          publicURL
        }
        photo {
          publicURL
        }
        logo {
          publicURL
        }
      }
      bigquote {
        text
        link
        logo {
          publicURL
        }
      }
      uws {
        title
        map {
          publicURL
        }
      }
      foot {
        title
        hero {
          publicURL
        }
      }
    }
  }
`;
