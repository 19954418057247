import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Titleh1 = styled.h1`
  transform: translateY(-200%);
  transition: opacity 2400ms, transform 3000ms;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transform: translateY(${({ isVisible }) => (isVisible ? '-200%' : '-800%')});
  font-size: ${px2rem(100)};
  color: black;

  ${MEDIA.DESKTOP`
      position: absolute;
      transform: translateY(-180%);
      left: 0;
      right: 0;
      margin: auto;
  `};

  ${MEDIA.TABLET`
    font-size: ${px2rem(80)};
    transform: translateY(-140%);
  `};
`;

export const AsideImg = styled.div`
  transition: opacity 4000ms, transform 2000ms;
  transform: translateY(${({ isVisible }) => (isVisible ? '-32%' : 0)});
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  width: ${px2rem(400)};
  height: ${px2rem(500)};

  ${MEDIA.DESKTOP`
    transform: translateY(0);
    margin: auto;
  `};

  img {
    width: 100%;
    height: 100%;
  }
`;

export const Container = styled.div`
  padding: ${px2rem(64)};
  text-align: center;
  color: #fff;
  transition: background-color 0.3s ease;

  ${MEDIA.TABLET`
    margin-bottom: 80px;
  `};

  a {
    text-decoration: none;
  }

  .main-img {
    transition: opacity 2000ms;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    max-width: ${px2rem(820)};
    max-height: ${px2rem(1025)};
    margin: auto;
  }

  .bottom-row {
    display: flex;
    width: 100%;
    max-width: ${px2rem(1440)};
    margin: auto;
    flex-direction: row;
    justify-content: space-between;

    ${MEDIA.DESKTOP`
      flex-direction: column;
    `};
  }

  p {
    transform: translateY(${({ isVisible }) => (isVisible ? 0 : '32%')});
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: transform 2000ms, opacity 5000ms;
    font-family: 'GTSuper';
    text-align: left;
    font-size: ${px2rem(25)};
    line-height: 2.04;
    max-width: ${px2rem(690)};
    color: black;
    ${MEDIA.DESKTOP`
      margin: 30px auto;
    `};

    ${MEDIA.TABLET`
      font-size: ${px2rem(27)};
      line-height: 1.65;
    `};
  }
`;
