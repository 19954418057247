import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem;
  background-color: black;
  color: white;

  h2 {
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 3200ms;

    &:before {
      content: '';
      display: block;
      width: ${px2rem(61)};
      height: 1px;
      background-color: white;
      margin: auto;
      margin-top: ${px2rem(50)};
      margin-bottom: ${px2rem(20)};
    }
    font-family: 'GTSuper';
    font-size: ${px2rem(18)};
    font-weight: 100;
    line-height: 1.33;
    letter-spacing: 1px;
    margin-bottom: ${px2rem(30)};
  }

  .logo-link {
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 1200ms;
  }

  h3 {
    width: ${px2rem(274)};
    height: ${px2rem(67)};
    border: solid 2px #ffffff;
    text-align: center;
    line-height: ${px2rem(67)};
    font-family: 'EngraversMT';
    font-size: ${px2rem(21)};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #ffffff;

    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 3400ms;
  }

  p {
    font-family: 'Arial';
    max-width: 700px;
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-top: ${px2rem(55)};
    margin-bottom: ${px2rem(55)};
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 3400ms;

    ${MEDIA.DESKTOP`
      font-size: 10px;
      max-width: 580px;
    `};
  }

  a {
    color: #f3f3f3;
    transition: color 0.2s ease;
    text-decoration: none;

    &:hover {
      h3 {
        transition: color 500ms, background-color 300ms;
        color: black;
        background-color: white;
      }
      color: inherit;
    }
  }

  .privacy-link {
    text-decoration: underline;

    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 2400ms;
  }

  .logo-row {
    display: flex;

    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: opacity 3200ms;

    a {
      text-align: center;
    }

    .spacer {
      margin: 40px;

      ${MEDIA.DESKTOP`
        margin: 10px;
      `};
    }

    ${MEDIA.DESKTOP`
      flex-direction: column;
    `};

    svg {
      padding: ${px2rem(10)};
      ${MEDIA.DESKTOP`
        max-width: 80%;
        margin: auto;
      `};
    }
  }
`;
