import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const LogoStyle = styled.div`
  width: 360px;

  ${MEDIA.DESKTOP`
      width: 240px;
  `};

  svg {
    width: 100%;
    max-height: 66px;
  }
`;
