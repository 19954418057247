import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.div`
  margin: auto;
  margin-bottom: ${px2rem(80)};
  max-width: ${px2rem(1280)};

  a {
    color: black;
    text-decoration: none;
  }

  h1 {
    font-family: 'GTSuper';
    font-size: ${px2rem(72)};
    line-height: 1.4;
    text-decoration: underline;
    transition: opacity 1000ms, transform 1400ms;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transform: translateX(${({ isVisible }) => (isVisible ? 0 : '-10%')});

    ${MEDIA.DESKTOP`
      font-size: ${px2rem(60)};
      margin-left: 20px;
    `};
  }

  img {
    margin-top: ${px2rem(50)};
    width: ${px2rem(280)};
    transition: opacity 2000ms, transform 1400ms;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transform: translateX(${({ isVisible }) => (isVisible ? 0 : '-10%')});

    ${MEDIA.DESKTOP`
      margin-left: 20px;
    `};
  }
`;
