import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const TitleDescribeContain = styled.div`
  position: absolute;
  z-index: 200;
  left: 0;
  right: 0;
  top: 50%;
  padding-left: 20px;
  transition: transform 2100ms, opacity;
  transform: translateY(${({ isVisible }) => (isVisible ? '-50%' : '-70%')});
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${MEDIA.DESKTOP`
      position: relative;
      transform: translateY(0);
      margin: 30px auto;
      padding: 0 20px;
  `};
`;

export const Titleh1 = styled.h1`
  font-size: ${px2rem(100)};
  color: black;
  text-align: left;
  margin-bottom: 20px;

  ${MEDIA.DESKTOP`
    font-size: ${px2rem(72)};
  `};

  ${MEDIA.TABLET`
    font-size: ${px2rem(80)};
  `};
`;

export const Description = styled.p`
  max-width: ${px2rem(709)};
  font-family: 'GTSuper';
  font-size: ${px2rem(25)};
  line-height: 2.04;
  text-align: left;
  color: #000000;

  ${MEDIA.DESKTOP`
    padding-right: 20px;
  `};

  ${MEDIA.TABLET`
    font-size: ${px2rem(27)};
    line-height: 1.65;
  `};
`;

export const AsideImg = styled.div`
  transition: opacity 800ms;
  max-width: ${px2rem(844)};
  width: 100%;
  max-height: ${px2rem(512)};
  object-fit: contain;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  ${MEDIA.DESKTOP`
    margin: auto;
  `};
  ${MEDIA.TABLET`
    width: calc(100% - 40px);
  `};
`;

export const Container = styled.div`
  color: #fff;

  overflow: auto;
  width: 100%;

  margin-bottom: 60px;

  ${MEDIA.DESKTOP`
      height: unset;
      justify-content: flex-start;
  `};

  ${MEDIA.TABLET`
      margin-bottom: 20px;
      margin-top: 90px;
  `};

  a {
    text-decoration: none;
  }

  .bottom-row {
    display: flex;
    max-width: ${px2rem(1440)};
    margin: auto;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;

    ${MEDIA.DESKTOP`
      flex-direction: column;
      margin-bottom: 20px;
    `};
  }

  p {
    font-family: 'GTSuper';
    text-align: left;
    font-size: ${px2rem(25)};
    line-height: 2.04;
    max-width: ${px2rem(690)};
    color: black;
  }
`;
