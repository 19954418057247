import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import { Container, Titleh1, AsideImg } from './imageHeroTitle.css';
import IO from 'components/io';

const BottomArea = ({ text, aside, title }) => {
  return (
    <IO rootMargin="150px">
      {({ isVisible }) => (
        <div className="bottom-row">
          <p>{text.childMarkdownRemark.rawMarkdownBody}</p>
          <AsideImg isVisible={isVisible}>
            <Img fluid={aside ? aside.childImageSharp.fluid : {}} alt={title} />
          </AsideImg>
        </div>
      )}
    </IO>
  );
};

const ImageHeroTitle = ({ image, title, text, aside, link }) => {
  return (
    <IO rootMargin="50px">
      {({ isVisible }) => (
        <Container isVisible={isVisible}>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <Img
              isVisible={isVisible}
              className="main-img"
              fluid={image ? image.childImageSharp.fluid : {}}
              alt={title}
            />
            <Titleh1 isVisible={isVisible}>{title}</Titleh1>
            <BottomArea text={text} aside={aside} title={title} />
          </a>
        </Container>
      )}
    </IO>
  );
};

BottomArea.propTypes = {
  text: PropTypes.object.isRequired,
  aside: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

ImageHeroTitle.propTypes = {
  text: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  aside: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default ImageHeroTitle;
