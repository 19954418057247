import React from 'react';
import PropTypes from 'prop-types';
import IO from 'components/io';
import { VideoContainer } from './videoHeader.css';

const VideoHeader = ({ children }) => (
  <IO rootMargin="-50px">
    {({ isVisible }) => (
      <VideoContainer isVisible={isVisible}>{children}</VideoContainer>
    )}
  </IO>
);

VideoHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default VideoHeader;
