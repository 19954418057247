import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { px2rem } from 'helpers/scssFunctions';

export const Titleh1 = styled.h1`
  font-size: ${px2rem(100)};
  line-height: 1;
  margin-bottom: ${px2rem(68)};
  transition-delay: 1000ms;
  transition: transform 1200ms, opacity 2300ms;
  transform: translateY(${({ isVisible }) => (isVisible ? '0' : '-20%')});
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};

  ${MEDIA.TABLET`
    font-size: ${px2rem(80)};
    margin-bottom: 40px;
  `};
`;

export const Container = styled.div`
  padding: 0;
  text-align: center;
  color: #fff;
  position: relative;
  height: ${px2rem(1067)};

  ${MEDIA.TABLET`
    height: unset;
    padding: 100px 10px;
  `};

  .main-img {
    position: absolute;
    width: ${px2rem(1061)};
    height: ${px2rem(1067)};
    right: 0;
    top: 0;
    object-fit: contain;
    transition-delay: 1000ms;
    transition: opacity 4000ms, transform 3000ms;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transform: translateY(${({ isVisible }) => (isVisible ? '0' : '20%')});

    ${MEDIA.TABLET`
      opacity: ${({ isVisible }) => (isVisible ? 0.5 : 0)};
      object-position: center center;
      width: 100%;
      height: 100%;
      top: 20px;
    `};
  }

  .bottom-row {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    color: black;

    ${MEDIA.TABLET`
      position: relative;
      transform: translateY(0);

      .getty-button {
        margin-bottom: 0;
      }

    `};

    .description {
      display: none;
    }

    ${MEDIA.TABLET`
      .description {
        top: 0;
        display: block;
        margin-bottom: 20px;
        position: relative;
      }
    `};

    ${MEDIA.DESKTOP`
      position: relative;
    `};
  }
`;
