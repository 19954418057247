import React from 'react';
import PropTypes from 'prop-types';
import { TextBlocker } from './textBlock.scss.js';
import IO from 'components/io';
import renderHTML from 'react-render-html';

const TextBlock = ({ text, showTopLine }) => {
  const classes = showTopLine ? 'showTopLine' : 'hideTopLine';

  return (
    <IO rootMargin="-50px">
      {({ isVisible }) => (
        <TextBlocker className={classes} isVisible={isVisible}>
          {renderHTML(text)}
        </TextBlocker>
      )}
    </IO>
  );
};

TextBlock.defaultProps = {
  showTopLine: false,
};

TextBlock.propTypes = {
  showTopLine: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

export default TextBlock;
