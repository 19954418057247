import React from 'react';
import PropTypes from 'prop-types';
import { Container, Locations } from './neighborhood.scss.js';
import IO from 'components/io';

const Neighborhood = ({ title, map }) => {
  const backgroundStyle = {
    backgroundImage: `url(${map.publicURL})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  };

  return (
    <IO rootMargin="200px">
      {({ isVisible }) => (
        <Container isVisible={isVisible} style={backgroundStyle}>
          <h1 className="headline">{title}</h1>

          <Locations isVisible={isVisible}>
            <h1>Culture</h1>
            <h2>The Whitney Museum</h2>
            <h2>The Shed</h2>
            <h2>DIA: Chelsea</h2>
            <h2>The Vessel</h2>
            <h2>The Joyce</h2>
            <h2>Pier 55</h2>
            <h1>Galleries</h1>
            <h2>Gagosian</h2>
            <h2>Lehmann Maupin</h2>
            <h2>Pace Gallery</h2>
            <h2>David Zwirner</h2>
            <h2>Luhring Augustine</h2>
            <h2>Hauser & Wirth</h2>
            <h1>Dining</h1>
            <h2>Chelsea Market</h2>
            <h2>Morimoto</h2>
            <h2>Del Posto</h2>
            <h2>Pastis</h2>
            <h2>Okuda</h2>
            <h2>L`Atelier de Joel Robuchon</h2>
            <h1>Lifestyle</h1>
            <h2>Hudson Yards</h2>
            <h2>Avenues World School</h2>
            <h2>The Highline</h2>
            <h2>Hudson River Park</h2>
            <h2>The Standard Hotel</h2>
            <h2>Hotel Americano</h2>
          </Locations>
        </Container>
      )}
    </IO>
  );
};

Neighborhood.propTypes = {
  title: PropTypes.string.isRequired,
  map: PropTypes.object.isRequired,
};

export default Neighborhood;
