import styled from 'styled-components';

export const Global = styled.div`
  width: 100%;
  margin: auto;
  background-color: white;
  overflow-x: hidden;

  @media screen and (min-width: 1920px) {
    width: 1920px;
  }
`;

export const SiteContain = styled.div`
  width: 100%;
  margin: auto;
  background-color: white;

  @media screen and (min-width: 1920px) {
    width: 1920px;
  }
`;
