import styled from 'styled-components';
import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

export const TextBlocker = styled.div`
  transition: background-color 0.3s ease;
  font-family: 'GTSuper';
  text-align: center;
  font-size: ${px2rem(31)};
  line-height: 1.65;
  margin: auto;
  margin-bottom: ${px2rem(30)};
  max-width: ${px2rem(1310)};
  color: black;

  p {
    transition: opacity 2000ms, transform 2000ms;
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transform: translateY(${({ isVisible }) => (isVisible ? '0%' : '20%')});
  }

  ${MEDIA.DESKTOP`
    padding: 0 30px;
    margin: 60px auto;

    &.showTopLine {
      margin-top: 0;
    }
  `};

  ${MEDIA.TABLET`
    text-align: left;
    font-size: ${px2rem(27)};
    margin: 30px auto;
    &.showTopLine {
      margin-top: 30px;
    }
  `};

  &.showTopLine::before {
    content: '';
    display: block;
    width: 2px;
    margin: auto;
    height: ${px2rem(120)};
    background-color: white;
    transform: translateX(-2px) translateY(-38.2%);
    transition: opacity 2000ms;
    transition-delay: 2000ms;
    opacity: ${({ isVisible }) => (isVisible ? 0 : 1)};

    ${MEDIA.TABLET`
      display: none;
    `};
  }
`;
