import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import PressGallery from 'components/pressGallery';
import Img from 'gatsby-image';
import PageHeader from 'components/pageHeader';
import { Navline } from 'styles/utility.scss.js';

const Press = ({ data }) => (
  <Layout>
    <Head pageTitle={data.pressJson.title} />
    <Navline />
    <PageHeader>
      <>
        <a
          href={data.pressJson.headerLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          <h1 style={{ textAlign: 'left' }}>
            {data.pressJson.quote}
            <br />
            <img
              src={data.pressJson.credit.publicURL}
              alt="Watch Logo"
              style={{ width: '200px' }}
            />
          </h1>
          <Img
            className="big-img"
            fluid={
              data.pressJson.header
                ? data.pressJson.header.childImageSharp.fluid
                : {}
            }
            alt="The Getty Residences Press"
          />
        </a>
      </>
    </PageHeader>

    <PressGallery items={data.pressJson.items} />
  </Layout>
);

Press.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Press;

export const query = graphql`
  query PressQuery {
    pressJson {
      title
      quote
      credit {
        publicURL
      }
      header {
        childImageSharp {
          fluid(maxHeight: 800, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      headerLink
      items {
        title
        link
        logo {
          childImageSharp {
            fluid(maxWidth: 256, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
