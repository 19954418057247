import { createGlobalStyle } from 'styled-components';
import { accent } from 'constants/theme';

import { px2rem } from 'helpers/scssFunctions';
import MEDIA from 'helpers/mediaTemplates';

import EngraversMTwoff from './fonts/EngraversMT.woff';
import EngraversMTeot from './fonts/EngraversMT.eot';
import EngraversMTttf from './fonts/EngraversMT.ttf';
import EngraversMTsvg from './fonts/EngraversMT.svg';

import GTSuperDisplayWoff from './fonts/GTSuperDisplay-Regular.woff';
import GTSuperDisplayEot from './fonts/GTSuperDisplay-Regular.eot';
import GTSuperDisplayTtf from './fonts/GTSuperDisplay-Regular.ttf';
import GTSuperDisplaySvg from './fonts/GTSuperDisplay-Regular.svg';

export default createGlobalStyle`

  @font-face {
    font-family: 'EngraversMT';
    src: url(${EngraversMTwoff}) format('woff');
    src: url(${EngraversMTeot}) format("embedded-opentype"),
         url(${EngraversMTwoff}) format("woff"),
         url(${EngraversMTttf}) format("truetype"),
         url(${EngraversMTsvg}) format("svg");
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;

  }

  @font-face {
    font-family: 'GTSuper';
    src: url(${GTSuperDisplayWoff}) format('woff');
    src: url(${GTSuperDisplayEot}) format("embedded-opentype"),
         url(${GTSuperDisplayWoff}) format("woff"),
         url(${GTSuperDisplayTtf}) format("truetype"),
         url(${GTSuperDisplaySvg}) format("svg");
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
    text-shadow: 0;
    text-rendering: geometricPrecision !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    font-family: 'GTSuper';
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html {
    font-size: 14px;
    ${MEDIA.WIDE`
      font-size: 12px;
    `};
    ${MEDIA.DESKTOP`
      font-size: 9px;
    `};
  }

  body {
    line-height: 1;
    font-size: ${px2rem(16)};
    color: #000;
    background-color: #fff;
    -moz-osx-font-smoothing: grayscale !important;
    text-shadow: 0;
    text-rendering: geometricPrecision !important;
    -webkit-font-smoothing: antialiased !important;
  }

  #___gatsby {
    margin: auto;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    color: ${accent};
  }

  video {
    max-width: 100%;
  }

`;
