import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './quotelogo.scss.js';
import IO from 'components/io';

const QuoteLogo = ({ quote, logo, logoSize, link }) => {
  return (
    <IO rootMargin="-50px">
      {({ isVisible }) => (
        <Container isVisible={isVisible} logoSize={logoSize}>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {'“'}
            {quote}
            {'”'}
            <img src={logo} alt="Getty" />
          </a>
        </Container>
      )}
    </IO>
  );
};

QuoteLogo.propTypes = {
  quote: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  logoSize: PropTypes.number,
  link: PropTypes.string,
};

QuoteLogo.defaultProps = {
  logoSize: 120,
};

export default QuoteLogo;
