import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import {
  Container,
  Image2Contain,
  Image3Contain,
  Caption,
} from './residence1.scss.js';
import IO from 'components/io';

const Residence1 = ({ image1, image2, image3, link, caption }) => {
  return (
    <IO rootMargin="-50px">
      {({ isVisible }) => (
        <Container isVisible={isVisible}>
          <a href={link} target="_blank" rel="noopener noreferrer">
            <Img
              className="main-img"
              fluid={image1 ? image1.childImageSharp.fluid : {}}
            />
            <Caption>{caption}</Caption>
            <Image2Contain isVisible={isVisible}>
              <Img
                className="image2"
                fluid={image2 ? image2.childImageSharp.fluid : {}}
              />
            </Image2Contain>

            <Image3Contain isVisible={isVisible}>
              <Img
                className="image3"
                fluid={image3 ? image3.childImageSharp.fluid : {}}
              />
            </Image3Contain>
          </a>
        </Container>
      )}
    </IO>
  );
};

Residence1.propTypes = {
  image1: PropTypes.object.isRequired,
  image2: PropTypes.object.isRequired,
  image3: PropTypes.object.isRequired,
  caption: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default Residence1;
